import React from 'react';
import { Route, Switch, useRouteMatch } from 'react-router';

import Actors from './actors';

const Index = () => {
  const { path } = useRouteMatch();

  return (
    <Switch>
      <Route path={`${path}/actors`}>
        <Actors />
      </Route>
    </Switch>
  );
};

export default Index;
