import axiosInstance from "../../../../utils/axios";
import { API_ENDPOINTS } from "../../../_APIConstants";
import { DashboardDesign } from '@mwaretv/database/build/backend/models/reporting/dashboardDesigns/dashboardDesign';
import { TvmsApiResponseData } from '@mwaretv/database/build/backend/interfaces/tvmsApiResponseData';
import { TvmsApiRequestData } from '@mwaretv/database/build/backend/interfaces/tvmsApiRequestData';

class DashboardService {
  Generate = async (requestData: TvmsApiRequestData): Promise<TvmsApiResponseData> => {

    return await axiosInstance
      .get<TvmsApiResponseData>(`${API_ENDPOINTS.V1.REPORTING.DASHBOARDS.GENERATE}/${requestData.urlParams.area}`, {
        params: requestData.queryParams,
      })
      .then((response) => {
        return response.data;
      })
      .catch((err) => {
        return err;
      });
  };

  Add = async (requestData: TvmsApiRequestData<DashboardDesign>): Promise<TvmsApiResponseData<DashboardDesign>> => {

    return await axiosInstance
      .post<TvmsApiResponseData<DashboardDesign>>(API_ENDPOINTS.V1.REPORTING.DASHBOARDS.ADD, requestData.payload, {
        params: requestData.queryParams,
      })
      .then((response) => {
        return response.data;
      })
      .catch((err) => {
        return err;
      });
  };

  List = async (requestData: TvmsApiRequestData): Promise<TvmsApiResponseData<DashboardDesign[]>> => {

    return await axiosInstance
      .post<TvmsApiResponseData<DashboardDesign[]>>(API_ENDPOINTS.V1.REPORTING.DASHBOARDS.LIST, requestData.payload, {
        params: requestData.queryParams,
      })
      .then((response) => {
        return response.data;
      })
      .catch((err) => {
        return err;
      });
  };

  Get = async (requestData: TvmsApiRequestData): Promise<TvmsApiResponseData<DashboardDesign>> => {

    return await axiosInstance
      .get<TvmsApiResponseData<DashboardDesign>>(`${API_ENDPOINTS.V1.REPORTING.DASHBOARDS.DETAILS}/${requestData.urlParams?.id}`, {
        params: requestData.queryParams,
      })
      .then((response) => {
        return response.data;
      })
      .catch((err) => {
        return err;
      });
  };

  Update = async (requestData: TvmsApiRequestData): Promise<TvmsApiResponseData<DashboardDesign>> => {

    return await axiosInstance
      .put<TvmsApiResponseData>(`${API_ENDPOINTS.V1.REPORTING.DASHBOARDS.UPDATE}/${requestData.urlParams?.id}`, requestData.payload, {
        params: requestData.queryParams,
      })
      .then((response) => {
        return response.data;
      })
      .catch((err) => {
        return err;
      });
  };

  Delete = async (requestData: TvmsApiRequestData): Promise<TvmsApiResponseData<DashboardDesign>> => {

    return await axiosInstance
      .delete<TvmsApiResponseData>(`${API_ENDPOINTS.V1.REPORTING.DASHBOARDS.DETAILS}/${requestData.urlParams?.id}`, {
        params: requestData.queryParams,
      })
      .then((response) => {
        return response.data;
      })
      .catch((err) => {
        return err;
      });
  };
}

const DASHBOARD_SERVICE = new DashboardService();
export default DASHBOARD_SERVICE;
