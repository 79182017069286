import { useTranslation } from 'react-i18next';
import { Link, useParams, useRouteMatch } from 'react-router-dom';
import { useContext, useEffect, useState } from 'react';
import * as HttpStatusCodes  from 'http-status-codes';
import { ReportingContext } from '../../../../contexts/reportingContext';
import { ContainerWrapper } from '../../../../common/components/containerWrapper';
import { IMSNameParams } from '../../../dashboard';
import { PaginationRequestData, TvmsApiRequestData } from '@mwaretv/database/build/backend/interfaces/tvmsApiRequestData';
import { TvmsApiResponseData } from '@mwaretv/database/build/backend/interfaces/tvmsApiResponseData';
import { ReportDesign } from '@mwaretv/database/build/backend/models/reporting/reportDesigns/reportDesign';
import type { DocumentType } from '@typegoose/typegoose';
import ReactBCTable from '../../../../common/components/ReactBCTable';
import dayjs from 'dayjs';
import localizedFormat from 'dayjs/plugin/localizedFormat';
import { IReactBCTableColumn } from '../../../../common/components/ReactBCTable/Interfaces/IReactBCTableColumn.interface';
import { Button, Col, Modal, Row, Tooltip } from 'antd';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import reportDesignService from '../../../../services/areas/reporting/reportdesigns/reportdesignservice';
import { faMemo, faTrashCan } from '@fortawesome/pro-regular-svg-icons';
dayjs.extend(localizedFormat);

const ReportDesignList = () => {
  const { t } = useTranslation();
  const { url } = useRouteMatch();
  const { clientName } = useParams<IMSNameParams>();
  const { deployment, service } = useContext(ReportingContext);
  const [pagination, setPagination] = useState<PaginationRequestData>({ pageSize: 50, pageNumber: 1 });
  const [isLoading, setIsLoading] = useState(true);
  const [isError, setIsError] = useState(false);
  const [responseData, setResponseData] = useState<TvmsApiResponseData<ReportDesign[]> | undefined>(undefined);
  const { confirm } = Modal;

  const getReportDesigns = async () => {
    setIsLoading(true);
    try {
      const requestData: TvmsApiRequestData = {
        queryParams: {
          clientName: clientName,
          deploymentName: deployment?.name ?? "",
          serviceName: service?.name ?? "",
        },
        payload: {
          pagination: pagination,
          sorting: {
            fieldName: "timestamp",
            descending: true,
          }
        }
      }

      const responseData = await reportDesignService.List(requestData);

      setResponseData(responseData);
    } catch (err) {
      console.log(err);
      setIsError(true);
    }
    setIsLoading(false);
  };

  const deleteReportDesign = async (id: string) => {
    setIsLoading(true);

    try {
      const requestData: TvmsApiRequestData = {
        urlParams: {
          id: id,
        },
        queryParams: {
          clientName: clientName,
          deploymentName: deployment?.name ?? "",
          serviceName: service?.name ?? "",
        },
      }
  
      const response = await reportDesignService.Delete(requestData);
  
      if (response && response.statusCode === HttpStatusCodes.OK) {
        await getReportDesigns();
        }
      else {
        console.log(response);
      }
    }
    catch (error) {
      console.log(error);
    }

    setIsLoading(false);
  };

  useEffect(() => {
    const fetchData = async () => {
      await getReportDesigns();
    };
    fetchData();
  }, [pagination.pageNumber]);

  const columns: IReactBCTableColumn<ReportDesign>[] = [
    {
      title: t('name'),
      dataKey: 'name',
    },
    {
      title: t('chart-design-date-created'),
      dataKey: 'createdAt',
      cell: (row: any) => {
        const displayValue = dayjs(row.createdAt).format("MMMM D, YYYY");

        return (
          <span>{displayValue}</span>
        );
      },
    },
    {
      dataKey: 'actions',
      title: t('actions'),
      width: '12%',
      cell: (row) => {
        return (
          <Col style={{ display: 'flex', flexDirection: 'row', height: 60 }}>
            <Row className="align-items-center">
              <div onClick={() => {
                  confirm({
                    title: t('report-design-delete-confirm'),
                    okText: t('yes'),
                    okType: 'danger',
                    cancelText: t('no'),
                    onOk() {
                      deleteReportDesign((row as DocumentType<ReportDesign>)?._id);
                    },
                    onCancel() {},
                  });
                }}
              >
                <Tooltip placement="top" title={t('delete')}>
                  <Button type="text" className="mx-2 cursor-pointer" style={{ height: '100%' }}>
                    <FontAwesomeIcon className="action-icon fa-trash-icon" icon={faTrashCan} />
                    <div>{t('delete')}</div>
                  </Button>
                </Tooltip>
              </div>
            </Row>
            <Row className="align-items-center">
              <a href={`${url}/details/${(row as DocumentType<ReportDesign>)?._id}`}>
                <Tooltip placement="top" title={t('open-details')}>
                  <Button type="text" className="mx-2 cursor-pointer" style={{ height: '100%' }}>
                    <FontAwesomeIcon className="action-icon" icon={faMemo} />
                    <div>{t('open-details')}</div>
                  </Button>
                </Tooltip>
              </a>
            </Row>
          </Col>
        );
      },
    },
  ];

  return (
    <ContainerWrapper>
      <div className="row">
        <div className="col-md-6 mb-2">
          <h2 className="page-title">{t('report-designer')}</h2>
        </div>
        <div className="col-md-4" />
        <div className="col-md-2 d-flex justify-content-center justify-content-md-end align-items-start">
          <Link className="btn btn-secondary rounded-pill btn-default float-right width-240 height-48" to={`${url}/add`}>
            {t('chart-design-add-report')}
          </Link>
        </div>
      </div>
      <div className="row">
        <div className="col-12 mt-4">
          {isError && <div>{t('something-went-wrong-sad-smiley')}</div>}
          {!isLoading && !isError && responseData?.data && (
            <ReactBCTable
              isLoading={isLoading}
              totalRecords={responseData.pagination?.totalDocs ?? responseData.data?.length ?? 0}
              pageSize={responseData.pagination?.limit ?? responseData.data?.length ?? 0}
              tableId={'reportdesigns_table'}
              columns={columns}
              data={responseData.data || []}
              currentPage={responseData.pagination?.page ?? 1}
              onChange={(page) => {
                setPagination({ ...pagination, pageNumber: page });
              }}
            />
          )}
        </div>
      </div>
    </ContainerWrapper>
  );
};

export default ReportDesignList;
