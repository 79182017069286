import { Button, Form, notification } from 'antd';

import { useForm } from 'antd/lib/form/Form';
import { useTranslation } from 'react-i18next';
import * as HttpStatusCodes from 'http-status-codes';
import { useContext, useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import ImageUploader from '../../../../../../../common/components/ImageUploader/ImageUploader';
import cmsService from '../../../../../../../services/areas/content/series/series';
import { IMSNameParams } from '../../../../../../dashboard';
import { ContentContext } from '../../../../../../../contexts/contentContext';
import { Series } from '../../../../../../../interfaces/forms/areas/content/series/series';
import { STORAGE_ENDPOINTS } from '../../../../../../../services/storage_paths';

const AddNewMusicAlbum = (data: any) => {
  const { clientName } = useParams<IMSNameParams>();
  const { service, deployment } = useContext(ContentContext);
  const [isAdding, setIsAdding] = useState(false);
  const [logo, setLogo] = useState([] as any);
  const [poster, setPoster] = useState([] as any);
  const [banner, setBanner] = useState([] as any);
  const [store, setStore] = useState([] as any);
  const [form] = useForm();
  const { t } = useTranslation();

  useEffect(() => {
    setStore(data.data.images.widescreen);
    setLogo(data.data.images.logo);
    setPoster(data.data.images.poster);
    setBanner(data.data.images.banner);
    form.setFieldsValue(data.data);
  }, [data]);

  const handleSubmit = async (values: Series) => {
    setIsAdding(true);
    if (data.data.payperview != undefined) {
      if (data.data.payperview.currencies != undefined) {
        if (data.data.payperview.currencies.length > 0) {
          if (data.data.payperview.currencies[0].price != undefined) {
            var curs = [] as any;
            deployment?.currencies.forEach((cur, index) => {
              curs.push({
                iso3: cur.isoCurrency,
                credits_rented: 0,
                credits_purchased: 0,
                key: index,
              });
            });
            data.data.payperview = {
              enable: data.data.payperview.enable,
              rule: undefined,
              currencies: curs,
            };
          } else {
            var curs = [] as any;
            deployment?.currencies.forEach((cur, index) => {
              curs.push({
                iso3: cur.isoCurrency,
                credits_rented: 0,
                credits_purchased: 0,
                key: index,
              });
            });
            data.data.payperview = {
              enable: data.data.payperview.enable,
              rule: undefined,
              currencies: curs,
            };
          }
        }
      }
    }
    values = {
      ...data.data,
      images: {
        widescreen: store.url == undefined ? (!Array.isArray(store) ? store : '') : store.url.replace('https://cloudtv.akamaized.net', ''),
        logo: logo?.url == undefined ? (!Array.isArray(logo) ? logo : '') : logo?.url?.replace('https://cloudtv.akamaized.net', ''),
        banner: banner?.url == undefined ? (!Array.isArray(banner) ? banner : '') : banner?.url?.replace('https://cloudtv.akamaized.net', ''),
        poster: poster.url == undefined ? (!Array.isArray(poster) ? poster : '') : poster?.url?.replace('https://cloudtv.akamaized.net', ''),
      },
      query_params: {
        instance: clientName,
        cms: deployment?.name ?? '',
      },
    };

    let response = await cmsService.Update_Series(values as any);
    if (response.status_code === HttpStatusCodes.OK) {
      notification.success({
        message: t('updated-successfully'),
      });
    } else {
      notification.error({
        message: t('an-error-occured'),
      });
    }
    setIsAdding(false);
  };

  return (
    <div className="container-fluid ">
      <Form
        form={form}
        onFinish={(values: any) => {
          handleSubmit(values);
        }}
        onFinishFailed={(errorInfo) => {}}
      >
        <div className="card pb-4">
          <div className="card-body card-ch-padding">
            <ImageUploader
              onSubmit={(values: any) => {
                setStore(values);
              }}
              initialData={{
                client: clientName,
                deployment: deployment,
                service: undefined,
                uploadedImage: store,
                uploadType: 'widescreen',
                uploadName: t('series-image'),
                path: STORAGE_ENDPOINTS(clientName, deployment?.name, service?.name).CONTENT.SERIES.IMAGES,
              }}
            />

            <ImageUploader
              onSubmit={(values: any) => {
                setLogo(values);
              }}
              initialData={{
                client: clientName,
                deployment: deployment,
                service: undefined,
                notMandatory: true,
                uploadName: t('series-logo'),
                uploadType: 'logo',
                uploadedImage: logo,
                path: STORAGE_ENDPOINTS(clientName, deployment?.name, service?.name).CONTENT.SERIES.IMAGES,
              }}
            />
            <ImageUploader
              onSubmit={(values: any) => {
                setPoster(values);
              }}
              initialData={{
                client: clientName,
                deployment: deployment,
                service: undefined,
                notMandatory: true,
                uploadName: t('series-poster'),
                uploadType: 'poster',
                uploadedImage: poster,
                path: STORAGE_ENDPOINTS(clientName, deployment?.name, service?.name).CONTENT.SERIES.IMAGES,
              }}
            />
            <ImageUploader
              onSubmit={(values: any) => {
                setBanner(values);
              }}
              initialData={{
                client: clientName,
                deployment: deployment,
                service: undefined,
                notMandatory: true,
                uploadName: t('series-banner'),
                uploadType: 'banner',
                uploadedImage: banner,
                path: STORAGE_ENDPOINTS(clientName, deployment?.name, service?.name).CONTENT.SERIES.IMAGES,
              }}
            />
          </div>
        </div>
        <div className="text-right">
          <Button htmlType="submit" disabled={isAdding} loading={isAdding} className="mt-4 mb-4 ant-btn btn btn-secondary rounded-pill width-240 height-48">
            {t('update-series')}
          </Button>
        </div>
      </Form>
    </div>
  );
};

export default AddNewMusicAlbum;
