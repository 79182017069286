import { faChartSimple, faDashboard, faFileChartPie } from '@fortawesome/pro-regular-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Layout, Menu } from 'antd';
import { Route, Switch, useRouteMatch } from 'react-router';
import { Link, useParams } from 'react-router-dom';
import MWare_Logo from '../../../assets/images/logo.png';
import MWare_Icon from '../../../assets/images/icon.png';
import IMSDashboardHeader from '../../../common/components/IMSDashboardHeader';
import * as DeploymentService from '../../../services/deployments/deployment';
import { useQuery } from '@tanstack/react-query';
import DashboardIndex from './dashboard/index';
import { useLocation } from 'react-use';
import { hasRole } from '../../../utils/Auth/role.auth';
import { AuthContext } from '../../../context_api/AuthContext';
import { useContext, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { ReportingContextProvider } from '../../../contexts/reportingContext';
import DesignerIndex from './designer';
import { TVMSRoles } from '../../../constants/TVMSRoles';
import PrivateRoute from '../../../common/routes/PrivateRoute';
import { isInRoles } from '../../../common/components/VisibleForRole';
import { getOpenKeys, getSelectedKey } from '../../../utils/menu';
import ReportDesignerIndex from './reportdesigner';
import DashboardDesignerIndex from './dashboarddesigner';

const Reporting = () => {
  const location = useLocation();
  const { t } = useTranslation();
  const { path, url } = useRouteMatch();
  const { clientName, deployment, service } = useParams<{ clientName: string; deployment: string; service: string }>();
  const [collapsed, setCollapsed] = useState(false);

  const { data, isLoading, isError } = useQuery(['deployment', deployment], async () => {
    return DeploymentService.find(clientName, { name: deployment })
      .then((r) => r.data)
      .catch((e) => Promise.reject(e));
  });

  const { state: AuthState } = useContext(AuthContext);
  const isClient = hasRole(AuthState.user!, clientName, ['superadmin', 'admin']);
  const hasEditPermissions = isInRoles(AuthState.user?.role, [TVMSRoles.SuperAdmin, TVMSRoles.Admin, TVMSRoles.Employee]);

  var locSplit = location?.pathname?.split('/');
  var redirectPath = '';
  if (locSplit != undefined) {
    redirectPath = locSplit.slice(0, 4 - locSplit.length).join('/');
  }

  let menuItems = [
    {
      icon: <FontAwesomeIcon icon={faDashboard} size="lg" style={{ width: '20', height: '20' }} />,
      label: (
        <div>
          <span>{t('dashboard')}</span>
        </div>
      ),
      key: 'dashboard',
      children: [
        {
          label: (
            <Link to={`${url}`}>
              <span>{t('dashboard')}</span>
            </Link>
          ),
          key: 'dashboard-dashboard',
        },
        {
          label: (
            <Link to={`${url}/dashboarddesigner`}>
              <span>{t('dashboard-designer')}</span>
            </Link>
          ),
          key: 'dashboard-designer',
        },
      ],
    },
    {
      icon: <FontAwesomeIcon icon={faChartSimple} size="lg" style={{ width: '20', height: '20' }} />,
      hidden: !hasEditPermissions,
      label: (
        <div>
          <span>{t('queries')}</span>
        </div>
      ),
      key: 'queries',
      children: [
        {
          label: (
            <Link to={`${url}/queries`}>
              <span>{t('queries')}</span>
            </Link>
          ),
          key: 'reporting-queries',
        },
      ],
    },
    {
      icon: <FontAwesomeIcon icon={faFileChartPie} size="lg" style={{ width: '20', height: '20' }} />,
      hidden: !hasEditPermissions,
      label: (
        <div>
          <span>{t('communications')}</span>
        </div>
      ),
      key: 'communications',
      children: [
        {
          label: (
            <Link to={`${url}/communications`}>
              <span>{t('communications')}</span>
            </Link>
          ),
          key: 'reporting-communications',
        },
      ],
    },
    // {
    //   icon: <FontAwesomeIcon icon={faFileChartPie} size="lg" style={{ width: '20', height: '20' }} />,
    //   hidden: !hasEditPermissions,
    //   label: (
    //     <div>
    //       <span>{t('history')}</span>
    //     </div>
    //   ),
    //   key: 'history',
    //   children: [
    //     {
    //       label: (
    //         <Link to={`${url}/history`}>
    //           <span>{t('history')}</span>
    //         </Link>
    //       ),
    //       key: 'reporting-history',
    //     },
    //   ],
    // },
  ];

  return (
    <Layout>
      <Layout.Sider width="300" collapsible collapsed={collapsed} onCollapse={(value) => {
          setCollapsed(value);
          window.dispatchEvent(new Event('resize'));
        }}
      >
        <div
          style={{
            justifyContent: 'center',
            alignContent: 'center',
            display: 'flex',
            padding: 40,
            paddingTop: 57,
            paddingBottom: 56,
            marginBottom: 20,
            borderBottom: '1px #444 solid',
            backgroundColor:'#002140'
          }}
        >
          {!isClient && (
            <Link to={'/'} onClick={() => {}} key="Mware">
              {!collapsed && <img className="expand" src={MWare_Logo} title={'MwareTV'} alt={'MwareTV'} style={{ height: 30 }} />}
              {collapsed && <img className="expand" src={MWare_Icon} title={'MwareTV'} alt={'MwareTV'} style={{ height: 30 }} />}
            </Link>
          )}
          {isClient && (
            <a href={redirectPath} key="Mware">
              {!collapsed && <img className="expand" src={MWare_Logo} title={'MwareTV'} alt={'MwareTV'} style={{ height: 30 }} />}
              {collapsed && <img className="expand" src={MWare_Icon} title={'MwareTV'} alt={'MwareTV'} style={{ height: 30 }} />}
            </a>
          )}
        </div>
        <Menu mode="inline" inlineCollapsed={collapsed} theme="dark" defaultSelectedKeys={getSelectedKey(location?.pathname ?? "", "reporting")} defaultOpenKeys={getOpenKeys(location?.pathname ?? "", "reporting")} items={menuItems} />
      </Layout.Sider>
      <Layout>
        <div style={{ paddingLeft: 0, width: '100%' }}>
          <div>
            <IMSDashboardHeader
              {...{
                isSideBarShow: true,
                path,
              }}
            />
          </div>
          {/* {isLoading && <Spin />}  */}
          {isError && <span>{t('something-went-wrong')}</span>}
          {!isLoading && !isError && data && (
            <ReportingContextProvider value={{ deployment: data, service: data.services.find((x) => x.name === service) }}>
              <Switch>
                <PrivateRoute path={`${path}/dashboarddesigner`} allowedRoles={[TVMSRoles.SuperAdmin, TVMSRoles.Admin, TVMSRoles.Employee]} component={DashboardDesignerIndex} />
                <PrivateRoute path={`${path}/queries`} allowedRoles={[TVMSRoles.SuperAdmin, TVMSRoles.Admin, TVMSRoles.Employee]} component={DesignerIndex} />
                <PrivateRoute path={`${path}/communications`} allowedRoles={[TVMSRoles.SuperAdmin, TVMSRoles.Admin, TVMSRoles.Employee]} component={ReportDesignerIndex} />
                <Route path={`${path}/history`}>{isClient && <DashboardIndex />}</Route>
                <Route path={`${path}`}>{isClient && <DashboardIndex />}</Route>
              </Switch>
            </ReportingContextProvider>
          )}
        </div>
      </Layout>
    </Layout>
  );
};

export default Reporting;
