import { Skeleton, Image } from 'antd';
import { useEffect, useState } from 'react';
import { Application } from '../../../../../interfaces/forms/areas/appbuilder/applications/application/application';
import { Banner_Rails } from '../../../../../interfaces/forms/areas/appbuilder/builder/elements/rails/banner/banner';
import moment from 'moment';
import axios from 'axios';
import { GetRailsElements, ImageRails, getTitle } from './helpers/helper';
import { useTranslation } from 'react-i18next';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faImage } from '@fortawesome/pro-regular-svg-icons';

export type LayoutProps = {
  data: Banner_Rails;
  application: Application;
};

const BannerRails = (props: LayoutProps) => {
  useEffect(() => {}, [props.data]);
  const [items, setItems] = useState([] as any);
  const [isActive, setIsActive] = useState(true);
  const [color, setColor] = useState('#999999');
  const { t } = useTranslation();

  useEffect(() => {
    async function fetchMyAPI() {
      if (props.data.type != undefined) {
        if (props.data.content.type === 'Content List') {
          try {
            var path = 'https://cloudtv.akamaized.net/' + props.application.client + '/' + props.application.deployment + '/' + props.data.content.list?.type?.toLocaleLowerCase() + '/lists/' + props.data.content.list?.list + '_list.json?t=' + moment().unix();
            var response = await axios.get(path);
            if (response.statusText == 'OK') {
              setItems(response.data[0].items);
            }
          } catch (e) {
            setColor('#bf8c8c');
          }
        } else {
          setIsActive(false);
        }
      } else {
        setIsActive(false);
      }
    }
    fetchMyAPI();
  }, [props.data]);

  return (
    <div style={{ backgroundColor: props.data.placement.background_color, height: 335 }}>
      <div
        className="container remove-hor-padding"
        style={{
          paddingTop: props.application.theme?.rails_top_margin ?? 5,
          paddingBottom: props.application.theme?.rails_bottom_margin ?? 5,
        }}
      >
        {props.data.content.list?.show_title != false && (
          <div className="text-left" style={{ marginLeft: 5 }}>
            {props.data.content.list?.title[0] && props.data.content.list?.title[0].title != 'Edit to add your text' && <>{getTitle(props.data)}</>}
            {(!props.data.content.list?.title[0] || props.data.content.list?.title[0].title == 'Edit to add your text') && <Skeleton.Input active={true} size={'large'} style={{ width: 200, height: 30, backgroundColor: '#bf8c8c' }} />}
          </div>
        )}
        {color == '#bf8c8c' && <div style={{ position: 'absolute', bottom: 0, right: 0, backgroundColor: 'crimson', color: '#ffffff', padding: 5, opacity: 0.85, fontSize: 12, fontWeight: 'bold', borderRadius: 5, marginRight: 10, marginBottom: 5, paddingLeft: 10, paddingRight: 10, zIndex: 100 }}>{t('publish-your-list')}</div>}
        <div className="container-fluid remove-hor-padding" style={{ height: 120 }}>
          <div style={{ flexDirection: 'row', display: 'flex', marginTop: props.application.theme?.rails_top_margin ?? 5, marginBottom: props.application.theme?.rails_bottom_margin ?? 5, marginRight: 5, marginLeft: 5 }}>
            <div style={{ backgroundColor: '#999', borderRadius: 5, marginTop: 10, border: '5px solid #333', marginRight: 5 }}>
              <div className="col-sm d-flex flex-wrap align-items-center" style={{ justifyContent: 'center', display: 'flex', height: '100%' }}>
                <FontAwesomeIcon icon={faImage} size={'3x'} color="#bfbfbf" />
              </div>
            </div>
            <div style={{ backgroundColor: '#999', borderRadius: 5, marginTop: 10, border: '5px solid #333', width: '100%' }}>
              <Skeleton.Node active={false} style={{ width: '100%', height: 200, backgroundColor: '#999' }}>
                <div className="col-sm d-flex flex-wrap align-items-center remove-hor-padding" style={{ justifyContent: 'center' }}>
                  <FontAwesomeIcon icon={faImage} size={'3x'} color="#bfbfbf" />
                </div>
              </Skeleton.Node>
            </div>
            <div style={{ backgroundColor: '#999', borderRadius: 5, marginTop: 10, border: '5px solid #333', marginLeft: 5 }}>
              <div className="col-sm d-flex flex-wrap align-items-center" style={{ justifyContent: 'center', display: 'flex', height: '100%' }}>
                <FontAwesomeIcon icon={faImage} size={'3x'} color="#bfbfbf" />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default BannerRails;
