import { Layout, Menu } from 'antd';
import { useState } from 'react';
import { Route, Switch, useParams, useRouteMatch } from 'react-router';
import { Link, useHistory } from 'react-router-dom';
import IMSDashboardHeader from '../../../common/components/IMSDashboardHeader';
import MWare_Logo from '../../../assets/images/logo.png';
import MWare_Icon from '../../../assets/images/icon.png';
import * as DeploymentService from '../../../services/deployments/deployment';
import { useQuery } from '@tanstack/react-query';
import { CustomersContextProvider } from '../../../contexts/customersContext';
import { faDashboard, faBadgePercent, faUsersViewfinder, faLinkHorizontal } from '@fortawesome/pro-regular-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import Affiliates from './affiliates';
import EmailGateways from '../billing/emailGateways';
import PushGateways from '../billing/pushGateways';
import SMSGateways from '../billing/smsGateways';
import Coupons from './coupons';
import Dashboard from './dashboard';
import { useEffectOnce, useLocation } from 'react-use';
import { AuthContext } from '../../../context_api/AuthContext';
import { useContext } from 'react';
import { hasRole } from '../../../utils/Auth/role.auth';
import { useTranslation } from 'react-i18next';
import { getOpenKeys, getSelectedKey } from '../../../utils/menu';

const Customer = () => {
  const location = useLocation();
  const { path, url } = useRouteMatch();
  const billingUrl = url.split('/').slice(0, -1).concat('billing').join('/');
  const { clientName, deployment, service } = useParams<{ clientName: string; deployment: string; service: string }>();
  const { state: AuthState } = useContext(AuthContext);
  const isClient = hasRole(AuthState.user!, clientName, ['superadmin', 'admin']);
  var locSplit = location?.pathname?.split('/');
  const history = useHistory();
  const { t } = useTranslation();
  var redirectPath = '';
  if (locSplit != undefined) {
    redirectPath = locSplit.slice(0, 4 - locSplit.length).join('/');
  }
  const [collapsed, setCollapsed] = useState(false);

  useEffectOnce(() => {
    const str = window.location.href;
    const parts = str.split('/');
    const lastPart = parts[parts.length - 1];
    if (!isClient && lastPart == 'customers') {
      history.push(url + '/customers/list');
    }
  });

  const { data, isLoading, isError } = useQuery(['deployment', deployment], async () => {
    return DeploymentService.find(clientName, { name: deployment })
      .then((r) => r.data)
      .catch((e) => Promise.reject(e));
  });

  return (
    <Layout>
      <Layout.Sider width="300" collapsible collapsed={collapsed} onCollapse={(value) => {
          setCollapsed(value);
          window.dispatchEvent(new Event('resize'));
        }}
      >
        <div
          style={{
            justifyContent: 'center',
            alignContent: 'center',
            display: 'flex',
            padding: 40,
            paddingTop: 57,
            paddingBottom: 56,
            marginBottom: 20,
            borderBottom: '1px #444 solid',
            backgroundColor:'#002140'
          }}
        >
          {!isClient && (
            <Link to={'/'} onClick={() => {}} key="Mware">
              {!collapsed && <img className="expand" src={MWare_Logo} title={'MwareTV'} alt={'MwareTV'} style={{ height: 30 }} />}
              {collapsed && <img className="expand" src={MWare_Icon} title={'MwareTV'} alt={'MwareTV'} style={{ height: 30 }} />}
            </Link>
          )}
          {isClient && (
            <a href={redirectPath} key="Mware">
              {!collapsed && <img className="expand" src={MWare_Logo} title={'MwareTV'} alt={'MwareTV'} style={{ height: 30 }} />}
              {collapsed && <img className="expand" src={MWare_Icon} title={'MwareTV'} alt={'MwareTV'} style={{ height: 30 }} />}
            </a>
          )}
        </div>
        <Menu
          mode="inline"
          inlineCollapsed={collapsed}
          theme="dark"
          defaultSelectedKeys={getSelectedKey(location?.pathname ?? "", "marketing")}
          defaultOpenKeys={getOpenKeys(location?.pathname ?? "", "marketing")}
        items={[
            isClient
              ? {
                  icon: <FontAwesomeIcon icon={faDashboard} size="lg" style={{ width: '20', height: '20' }} />,
                  label: (
                    <div>
                      <span>{t('dashboard')}</span>
                    </div>
                  ),
                  key: 'dashboard',
                  children: [
                    {
                      label: (
                        <Link to={`${url}`}>
                          <span>{t('dashboard')}</span>
                        </Link>
                      ),
                      key: 'dashboard-dashboard',
                    },
                  ],
                }
              : {
                  label: <></>,
                  key: 'empty',
                },
            {
              icon: <FontAwesomeIcon icon={faUsersViewfinder} size="lg" style={{ width: '20', height: '20' }} />,
              label: (
                <div>
                  <span>{t('affiliates')}</span>
                </div>
              ),
              key: 'affiliates',
              children: [
                {
                  label: (
                    <Link to={`${url}/affiliates/list`}>
                      <span>{t('affiliates')}</span>
                    </Link>
                  ),
                  key: 'affiliates_',
                },
              ],
            },
            {
              icon: <FontAwesomeIcon icon={faBadgePercent} size="lg" style={{ width: '20', height: '20' }} />,
              label: (
                <div>
                  <span>{t('coupons')}</span>
                </div>
              ),
              key: 'coupons',
              children: [
                {
                  label: (
                    <Link to={`${url}/coupons/list`}>
                      <span>{t('coupons')}</span>
                    </Link>
                  ),
                  key: 'coupons_',
                },
              ],
            },
            {
              icon: <FontAwesomeIcon icon={faLinkHorizontal} size="lg" style={{ width: '20', height: '20' }} />,
              label: (
                <div>
                  <span>{t('gateways')}</span>
                </div>
              ),
              key: 'gateways',
              children: [
                {
                  label: (
                    <div>
                      <span>{t('email-gateways')}</span>
                    </div>
                  ),
                  key: 'email-gateways',
                  children: [
                    {
                      label: (
                        <Link to={`${billingUrl}/email-gateways`}>
                          <span>{t('email-gateways')}</span>
                        </Link>
                      ),
                      key: 'email-gateway',
                    },
                    {
                      label: (
                        <Link to={`${billingUrl}/email-gateways/my-gateways`}>
                          <span>{t('my-email-gateways')}</span>
                        </Link>
                      ),
                      key: 'my-email-gateways',
                    },
                  ],
                },
                {
                  label: (
                    <div>
                      <span>{t('sms-gateways')}</span>
                    </div>
                  ),
                  key: 'sms-gateways',
                  children: [
                    {
                      label: (
                        <Link to={`${billingUrl}/sms-gateways`}>
                          <span>{t('sms-gateways')}</span>
                        </Link>
                      ),
                      key: 'sms-gateway',
                    },
                    {
                      label: (
                        <Link to={`${billingUrl}/sms-gateways/my-gateways`}>
                          <span>{t('my-sms-gateways')}</span>
                        </Link>
                      ),
                      key: 'my-sms-gateways',
                    },
                  ],
                },
                {
                  label: (
                    <div>
                      <span>{t('push-gateways')}</span>
                    </div>
                  ),
                  key: 'push-gateways',
                  children: [
                    {
                      label: (
                        <Link to={`${billingUrl}/push-gateways`}>
                          <span>{t('push-gateways')}</span>
                        </Link>
                      ),
                      key: 'push-gateway',
                    },
                    {
                      label: (
                        <Link to={`${billingUrl}/push-gateways/my-gateways`}>
                          <span>{t('my-push-gateways')}</span>
                        </Link>
                      ),
                      key: 'my-push-gateways',
                    },
                  ],
                },
              ],
            },
          ]}
        />
      </Layout.Sider>
      <Layout>
        <div style={{ paddingLeft: 0, width: '100%' }}>
          <div>
            <IMSDashboardHeader
              {...{
                isSideBarShow: true,
                path,
              }}
            />
          </div>
          <div>
            {/* {isLoading && <Spin />} */}
            {isError && <span>{t('something-went-wrong')}</span>}
            {!isLoading && !isError && data && (
              <CustomersContextProvider value={{ deployment: data, service: data.services.find((x) => x.name === service) }}>
                <Switch>
                  <Route path={`${path}/affiliates`}>
                    <Affiliates />
                  </Route>
                  <Route path={`${path}/coupons`}>
                    <Coupons />
                  </Route>
                  <Route path={`${path}/sms-gateways`}>
                  <SMSGateways />
                </Route>
                <Route path={`${path}/email-gateways`}>
                  <EmailGateways />
                </Route>
                <Route path={`${path}/push-gateways`}>
                  <PushGateways />
                </Route>

                  <Route path={`${path}/`}>{isClient && <Dashboard />}</Route>
                </Switch>
              </CustomersContextProvider>
            )}
          </div>
        </div>
      </Layout>
    </Layout>
  );
};

export default Customer;
