import { Route, Switch, useRouteMatch } from 'react-router-dom';
import AddReportDesign from '../reportdesigner/add';
import DetailsReportDesign from '../reportdesigner/details';
import ReportDesignList from './list';

const ReportDesignerIndex = () => {
  const { path } = useRouteMatch();

  return (
    <Switch>
      <Route path={`${path}/add`}>
        <AddReportDesign />
      </Route>
      <Route path={`${path}/details/:_id`}>
        <DetailsReportDesign />
      </Route>
      <Route path={`${path}`}>
        <ReportDesignList />
      </Route>
    </Switch>

  );
};

export default ReportDesignerIndex;
