import { Button, Form, Input, Switch, notification } from 'antd';
import { useTranslation } from 'react-i18next';
import { STORAGE_ENDPOINTS } from '../../../../services/storage_paths';
import VideoUploader from '../../VIdeoUploader/VideoUploader';
import { useHistory, useParams } from 'react-router-dom';
import { useContext, useState } from 'react';
import { IMSNameParams } from '../../../../pages/dashboard';
import cmsService from '../../../../services/areas/content/movies/movies';
import * as HttpStatusCodes from 'http-status-codes';
import { ContentContext } from '../../../../contexts/contentContext';

const Streams = () => {
  const { t } = useTranslation();
  const [file, setFile] = useState([] as any);
  const [isAdding, setIsAdding] = useState(false);
  const { clientName, _id } = useParams<IMSNameParams>();
  const { deployment } = useContext(ContentContext);
  const history = useHistory();

  const startScrubber = async () => {
    setIsAdding(true);
    let response = await cmsService.Srubber_Movie({
      query_params: {
        instance: clientName,
        cms: deployment?.name ?? '',
      },
      _id: _id,
    });
    if (response.status_code === HttpStatusCodes.OK) {
      notification.success({
        message: t('added-successfully'),
      });
      history.go(0);
    } else {
      notification.error({
        message: t('an-error-occured'),
      });
    }
    setIsAdding(false);
  };
  return (
    <div>
      <h4 className="text-primary mt-5 mb-3">{t('streams')}</h4>
      <div className="row">
        <div className="col-md-12 card-bg-light p-5">
          <div className="row">
            <label className="col-md-2 col-form-label">{t('url')}</label>
            <Form.Item
              className="col-md-10"
              name={['streams', 'movies', 'url']}
              extra={t('we-recommend-to-use-mp4-or-dash')}
              rules={[
                {
                  required: true,
                  message: t('url-is-required'),
                },
              ]}
            >
              <Input type={'url'} className="form-control bg-white font-size-14 text-default" placeholder="https://stream.com" />
            </Form.Item>
          </div>

          <div className="row">
            <label className="col-md-2 col-form-label" style={{ marginBottom: 0, paddingBottom: 0 }}>
              {t('thumbnail-vtt')}
            </label>
            <Form.Item style={{ marginBottom: 0, paddingBottom: 0 }} extra={t('add-netflix-style-thumbnail-preview-search-to-your-content')} className="col-md-10" name={['streams', 'movies', 'thumbnails']}>
              <Input className="form-control bg-white font-size-14 text-default" placeholder="" />
            </Form.Item>
          </div>
          <div className="row mb-4">
            <label className="col-md-2 col-form-label"></label>
            <div className="col-md-10">
              <Button onClick={() => startScrubber()} disabled={isAdding} loading={isAdding} className="mb-4 ant-btn btn btn-secondary rounded-pill width-240 height-48">
                Generate Thumbnail Track
              </Button>
            </div>
          </div>
          <div className="row">
            <label className="col-md-2 col-form-label">{t('subtitle-vtt')}</label>
            <Form.Item extra={t('add-optional-external-subtitle-tracks')} className="col-md-10" name={['streams', 'movies', 'subtitles']}>
              <Input  className="form-control bg-white font-size-14 text-default" placeholder="" />
            </Form.Item>
          </div>
          <div className="row">
            <label className="col-md-2 col-form-label">{t('trailer-url')}</label>
            <Form.Item className="col-md-10" name={['streams', 'movies', 'trailer']} extra={t('we-recommend-to-use-mp4-or-dash')}>
              <Input type={'url'} className="form-control bg-white font-size-14 text-default" placeholder="https://stream.com" />
            </Form.Item>
          </div>
          <div className="row">
            <label className="col-md-2 col-form-label">{t('allow-download')}</label>
            <Form.Item extra={t('give-customers-the-option-to-download-the-content-on-their-mobile-phones-and-play-back-offline')} className="col-md-5" name={['streams', 'movies', 'allow_downloads']} valuePropName="checked">
              <Switch className="ch-switch mt-3" />
            </Form.Item>
          </div>
          <div className="row">
            <label className="col-md-2 col-form-label">{t('expiration-download')}</label>
            <Form.Item extra={t('specify-the-duration-in-days-during-which-downloaded-content-items-will-be-automatically-deleted-from-the-customers-device')} className="col-md-5" name={['streams', 'movies', 'expiration_downloads']}>
              <Input type={'number'} min={1} className="form-control bg-white font-size-14 text-default" placeholder="1" />
            </Form.Item>
          </div>
          {/* <div className="row">
            <h4 className="text-primary mt-5 mb-3">Movie Uploader</h4>

            <div className="col-md-12">
              <div className="row">
                <VideoUploader
                  onSubmit={(values: any) => {
                    setFile(values);
                  }}
                  initialData={{
                    notMandatory: file != undefined && file != '' ? true : false,
                    path: STORAGE_ENDPOINTS(clientName, deployment?.name, '').CONTENT.MOVIES.STREAMS,
                  }}
                ></VideoUploader>
              </div>
            </div>
          </div> */}
        </div>

        <div className="col">
          <h4 className="text-primary mt-5 mb-3">Deeplink</h4>
          <p>Deeplinks are hyperlinks that point directly to specific content within an app.</p>
          <div className="col-md-12 card-bg-light p-5">
            <div className="row">
              <label className="col-md-3 col-form-label">Deeplink</label>
              <Form.Item className="col-md-10" name={['streams', 'movies', 'deeplink']}>
                <Input type={'url'} className="form-control bg-white font-size-14 text-default" placeholder="dplnk://stream.com" />
              </Form.Item>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
export default Streams;
