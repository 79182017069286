import { Form, Modal, Select, Switch } from 'antd';
import { useForm } from 'antd/lib/form/Form';
import { useTranslation } from 'react-i18next';
import { useContext, useEffect, useState } from 'react';
import channelList from '../../../../services/areas/content/channels/lists';
import radioList from '../../../../services/areas/content/radio/lists';
import camList from '../../../../services/areas/content/cams/lists';
import movieList from '../../../../services/areas/content/movies/lists';
import seriesList from '../../../../services/areas/content/series/lists';
import musicList from '../../../../services/areas/content/albums/lists';
import podcastList from '../../../../services/areas/content/podcasts/lists';
import courseList from '../../../../services/areas/content/courses/lists';
import gamesList from '../../../../services/areas/content/games/lists';
import { useParams } from 'react-router-dom';
import { AppBuilderContext } from '../../../../contexts/appbuilderContext';
import { IMSNameParams } from '../../../../pages/dashboard';
import Translations from '../Translations/Translations';
import ImageUploader from '../../ImageUploader/ImageUploader';
import { STORAGE_ENDPOINTS } from '../../../../services/storage_paths';

export type LayoutProps = {
  visible: boolean;
  onSubmit: (component: [], name: string, index: any) => void;
  onCancel: () => void;
  data: [] | any;
  type: string;
  index: any;
};

const LayoutModel = (props: LayoutProps) => {
  const { visible, onSubmit, onCancel, data, type } = props;
  const [form] = useForm();
  const { t } = useTranslation();
  const { clientName } = useParams<IMSNameParams>();
  const { deployment } = useContext(AppBuilderContext);

  const [translationsTitle, setTranslationsTitle] = useState([] as any);
  const [translationsLocalTitle, setTranslationsLocalTitle] = useState([] as any);

  const [translationsTagline, setTranslationsTagline] = useState([] as any);
  const [translationsLocalTagline, setTranslationsLocalTagline] = useState([] as any);

  const [contentType, setContentType] = useState('');
  const [listType, setListType] = useState('');
  const [list, setList] = useState('');
  const [listName, setListName] = useState('');
  const [contentList, setContentList] = useState([] as any);
  const [showTitle, setShowTitle] = useState(true);
  const [enableOverview, setEnableOverview] = useState(true);
  const [linkToHero, setLinkToHero] = useState(false);
  const [directToPlayer, setDirectToPlayer] = useState(false);
  const [pressAndHold, setPressAndHold] = useState(true);
  const [pressAndHoldMessage, setPressAndHoldMessage] = useState(true);
  const [imageUrl, setImageUrl] = useState([] as any);
  const [imageBackgroundLandscapeUrl, setImageBackgroundLandscapeUrl] = useState([] as any);
  const [imageBackgroundPortraitUrl, setImageBackgroundPortraitUrl] = useState([] as any);
  const [enableDeeplinking, setEnableDeeplinking] = useState(false);

  const onSubmitLocal = (values: any) => {
    var translations_ = [] as any;
    translationsLocalTitle.forEach((element: any) => {
      translations_.push({
        language: element.language,
        title: element.title,
      });
    });

    if (translationsLocalTitle[0]?.title == 'Edit to add your text') {
      translationsTitle.forEach((element: any) => {
        translations_.push({
          language: element.language,
          title: element.title,
        });
      });
    }

    var translationsTagline_ = [] as any;
    translationsLocalTagline.forEach((element: any) => {
      translationsTagline_.push({
        language: element.language,
        title: element.title,
      });
    });

    if (translationsLocalTagline[0]?.title == 'Edit to add your text') {
      translationsTagline.forEach((element: any) => {
        translationsTagline_.push({
          language: element.language,
          title: element.title,
        });
      });
    }

    var content1 = {
      type: contentType, //Favorites, Watchlist, Lists
      capped: false,
      link_to_hero: data.type == 'Single' || data.type == 'Slider' || data.type == 'Player' ? false : linkToHero,
      play_directly: directToPlayer,
      press_and_hold: pressAndHold,
      press_and_hold_message: pressAndHoldMessage,
      enable_deeplinking: enableDeeplinking,
      list: {
        type: listType, //Channels, Radio, Cams, etc
        list: list,
        name: listName,
        show_title: showTitle,
        enable_overview: enableOverview,
        title: translations_ ?? [],
        tagline: translationsTagline_ ?? [],
        image: imageUrl?.url ?? '',
        background_portrait: imageBackgroundPortraitUrl?.url ?? '',
        background_lanscape: imageBackgroundLandscapeUrl?.url ?? '',
      },
    };
    let dataOut = {
      ...data,
      content: content1,
    };

    onSubmit(dataOut, type, props.index);
    //}
  };

  useEffect(() => {
    async function fetchData() {
      if (data.content != undefined) {
        setContentTypeLocal(data.content.type);
        setContentType(data.content.type);
        setLinkToHero(data.content.link_to_hero);
        setDirectToPlayer(data.content.play_directly);
        setEnableDeeplinking(data.content.enable_deeplinking ?? false);

        if (data.content.list != undefined) {
          setList(data.content.list.list);
          setListName(data.content.list.name);
          setContentListTypeLocal(data.content.list.type);
          setListType(data.content.list.type);
          setTranslationsTitle(data.content.list.title);
          setTranslationsTagline(data.content.list.tagline);
          setShowTitle(data.content.list.show_title);
          setEnableOverview(data.content.list.enable_overview);
          setImageUrl(data.content.list.image);
          setImageBackgroundPortraitUrl(data.content.list.background_portrait);
          setImageBackgroundLandscapeUrl(data.content.list.background_portrait);
        }

        form.setFieldsValue(data.content);
      }
    }
    fetchData();
  }, [data, form]);

  const setContentTypeLocal = (value: any) => {
    setContentType(value);
  };
  const setContentListTypeLocal = (value: any) => {
    setListType(value);
    getContentList(value);
  };

  const getContentList = (type: any) => {
    switch (type) {
      case 'Channels':
        channelList
          .List_Lists({
            pageNumber: 0,
            pageSize: 1000,
            query_params: {
              cms: deployment?.name ?? '',
              instance: clientName,
              path: undefined,
              remove: undefined,
              overwrite: undefined,
            },
          })
          .then((result: any) => {
            setContentList(result.data.data);
          });
        break;
      case 'Radio':
        radioList
          .List_Lists({
            pageNumber: 0,
            pageSize: 1000,
            query_params: {
              cms: deployment?.name ?? '',
              instance: clientName,
              path: undefined,
              remove: undefined,
              overwrite: undefined,
            },
          })
          .then((result: any) => {
            setContentList(result.data.data);
          });
        break;
      case 'Cams':
        camList
          .List_Lists({
            pageNumber: 0,
            pageSize: 1000,
            query_params: {
              cms: deployment?.name ?? '',
              instance: clientName,
              path: undefined,
              remove: undefined,
              overwrite: undefined,
            },
          })
          .then((result: any) => {
            setContentList(result.data.data);
          });
        break;
      case 'CatchupTV':
        channelList
          .List_Lists({
            pageNumber: 0,
            pageSize: 1000,
            query_params: {
              cms: deployment?.name ?? '',
              instance: clientName,
              path: undefined,
              remove: undefined,
              overwrite: undefined,
            },
          })
          .then((result: any) => {
            setContentList(result.data.data);
          });
        break;
      case 'Movies':
        movieList
          .List_Lists({
            pageNumber: 0,
            pageSize: 1000,
            query_params: {
              cms: deployment?.name ?? '',
              instance: clientName,
              path: undefined,
              remove: undefined,
              overwrite: undefined,
            },
          })
          .then((result: any) => {
            setContentList(result.data.data);
          });
        break;
      case 'Series':
        seriesList
          .List_Lists({
            pageNumber: 0,
            pageSize: 1000,
            query_params: {
              cms: deployment?.name ?? '',
              instance: clientName,
              path: undefined,
              remove: undefined,
              overwrite: undefined,
            },
          })
          .then((result: any) => {
            setContentList(result.data.data);
          });
        break;
      case 'Music':
        musicList
          .List_Lists({
            pageNumber: 0,
            pageSize: 1000,
            query_params: {
              cms: deployment?.name ?? '',
              instance: clientName,
              path: undefined,
              remove: undefined,
              overwrite: undefined,
            },
          })
          .then((result: any) => {
            setContentList(result.data.data);
          });
        break;
      case 'Podcasts':
        podcastList
          .List_Lists({
            pageNumber: 0,
            pageSize: 1000,
            query_params: {
              cms: deployment?.name ?? '',
              instance: clientName,
              path: undefined,
              remove: undefined,
              overwrite: undefined,
            },
          })
          .then((result: any) => {
            setContentList(result.data.data);
          });
        break;
      case 'Courses':
        courseList
          .List_Lists({
            pageNumber: 0,
            pageSize: 1000,
            query_params: {
              cms: deployment?.name ?? '',
              instance: clientName,
              path: undefined,
              remove: undefined,
              overwrite: undefined,
            },
          })
          .then((result: any) => {
            setContentList(result.data.data);
          });
        break;
      case 'Games':
        gamesList
          .List_Lists({
            pageNumber: 0,
            pageSize: 1000,
            query_params: {
              cms: deployment?.name ?? '',
              instance: clientName,
              path: undefined,
              remove: undefined,
              overwrite: undefined,
            },
          })
          .then((result: any) => {
            setContentList(result.data.data);
          });
        break;
      default:
        break;
    }
  };
  const setListLocal = (value: any) => {
    setList(value);
    var test = contentList.find((d) => d._id == value);
    if (test != undefined) {
      setListName(test.name);
    }
  };

  return (
    <Modal visible={visible} style={{ border: '2px solid #ebebeb', borderRadius: 10 }} width={'80%'} footer={false} closable={true} onCancel={onCancel}>
      <div className="p-5 mt-4" style={{ border: '1px solid #ebebeb' }}>
        <div className="row justify-content-center">
          <div className="col-md-12">
            <h4 className="text-primary">{t('edit-content-settings')}</h4>

            <div className="row mt-5">
              <div className="col-12">
                <Form form={form} onFinish={onSubmitLocal} onFinishFailed={(errorInfo) => {}}>
                  <div className="col">
                    <div className="col" style={{ padding: 15 }}>
                      <div className="col card-bg-light p-4 mb-2 mr-1" style={{ paddingLeft: 0 }}>
                        <h5 className="text-primary">{t('content')}</h5>
                        <p>{t('select-the-content-type-you-want-to-show-in-the-rails-component')}</p>
                        {data.type != 'Genre Rails' && (
                          <div className="row">
                            <label className="col-md-4 col-form-label">List Type</label>
                            <div className="col" style={{ paddingLeft: 0 }}>
                              <Form.Item name={'type'} className="form-group pb-0">
                                <Select onChange={(value) => setContentTypeLocal(value)} className="default-select" placeholder="- Select Type -">
                                  {type != 'Single' && type != 'Slider' && type != 'Player' && type != 'Banner Rails' && (
                                    <Select.Option value={'Favorites'} key={'Favorites'}>
                                      {t('favorites')}
                                    </Select.Option>
                                  )}
                                  {type != 'Single' && type != 'Slider' && type != 'Player' && type != 'Banner Rails' && (
                                    <Select.Option value={'Watched'} key={'Watched'}>
                                      {t('watched')}
                                    </Select.Option>
                                  )}
                                  {type != 'Single' && type != 'Slider' && type != 'Player' && (
                                    <Select.Option value={'External'} key={'External'}>
                                      {t('external')}
                                    </Select.Option>
                                  )}
                                  {type != 'Single' && type != 'Slider' && type != 'Player' && type != 'Banner Rails' && (
                                    <Select.Option value={'Search External'} key={'Search External'}>
                                      {t('search-external')}
                                    </Select.Option>
                                  )}
                                  {type != 'Single' && type != 'Slider' && type != 'Player' && type != 'Banner Rails' && (
                                    <Select.Option value={'Rented'} key={'Rented'}>
                                      {t('rented')}
                                    </Select.Option>
                                  )}
                                  {type != 'Single' && type != 'Slider' && type != 'Player' && type != 'Banner Rails' && (
                                    <Select.Option value={'Purchased'} key={'Purchased'}>
                                      {t('purchased')}
                                    </Select.Option>
                                  )}
                                  {type != 'Single' && type != 'Slider' && type != 'Player' && type != 'Banner Rails' && (
                                    <Select.Option value={'Downloads'} key={'Downloads'}>
                                      {t('downloads')}
                                    </Select.Option>
                                  )}
                                  {type != 'Single' && type != 'Slider' && type != 'Player' && type != 'Banner Rails' && (
                                    <Select.Option value={'Reminders'} key={'Reminders'}>
                                      {t('reminders')}
                                    </Select.Option>
                                  )}

                                  {/* <Select.Option value={'Recommendation'} key={'Recommendation'}>Recommendation</Select.Option> */}
                                  {type != 'Single' && type != 'Slider' && type != 'Player' && (
                                    <Select.Option value={'Search'} key={'Search'}>
                                      {t('search')}
                                    </Select.Option>
                                  )}
                                  <Select.Option value={'Content List'} key={'Content List'}>
                                    {t('content-list')}
                                  </Select.Option>
                                  {type != 'Single' && type != 'Slider' && type != 'Player' && type != 'Banner Rails' && type != 'Long Rails' && contentType != 'Downloads' && type != 'Player Rails' && type != 'Morphing Rails' && type != 'Rectangle Rails' && type != 'Genre Rails' && type != 'Menu Rails' && contentType != 'External' && contentType != 'Search External' && (
                                    <Select.Option value={'AppsAndroidTV'} key={'AppsAndroidTV'}>
                                      Apps AndroidTV
                                    </Select.Option>
                                  )}
                                </Select>
                              </Form.Item>
                            </div>
                          </div>
                        )}
                        {data.type == 'Genre Rails' && (
                          <div className="row">
                            <label className="col-md-4 col-form-label">{t('content-type')}</label>
                            <div className="col" style={{ paddingLeft: 0 }}>
                              {type != 'Single' && type != 'Slider' && type != 'Player' && (
                                <Form.Item name={['list', 'type']} className="form-group pb-0">
                                  <Select onChange={setContentListTypeLocal} className="default-select" placeholder="- Select Type -">
                                    {type != 'Banner Rails' && contentType != 'Downloads' && type != 'Morphing Rails' && type != 'Rectangle Rails' && contentType != 'External' && contentType != 'Search External' && (
                                      <Select.Option value={'Channels'} key={'Channels'}>
                                        {t('channels')}
                                      </Select.Option>
                                    )}
                                    {type != 'Banner Rails' && contentType != 'Downloads' && type != 'Player Rails' && type != 'Morphing Rails' && type != 'Rectangle Rails' && type != 'Wide Rails' && type != 'Player' && contentType != 'External' && contentType != 'Search External' && (
                                      <Select.Option value={'Radio'} key={'Radio'}>
                                        {t('radio')}
                                      </Select.Option>
                                    )}
                                    {type != 'Banner Rails' && contentType != 'Downloads' && type != 'Morphing Rails' && type != 'Rectangle Rails' && contentType != 'External' && contentType != 'Search External' && (
                                      <Select.Option value={'Cams'} key={'Cams'}>
                                        {t('cams')}
                                      </Select.Option>
                                    )}

                                    {type != 'Banner Rails' && contentType != 'Downloads' && type != 'Player Rails' && data.type != 'Genre Rails' && type != 'Morphing Rails' && type != 'Rectangle Rails' && type != 'Round Rails' && type != 'Rectangle Rails' && type != 'Player' && contentType != 'External' && contentType != 'Search External' && (
                                      <Select.Option value={'CatchupTV'} key={'CatchupTV'}>
                                        CatchupTV
                                      </Select.Option>
                                    )}
                                    {type != 'Banner Rails' && contentType != 'Downloads' && type != 'Player Rails' && data.type != 'Genre Rails' && type != 'Morphing Rails' && type != 'Rectangle Rails' && type != 'Round Rails' && type != 'Rectangle Rails' && type != 'Player' && contentType != 'External' && contentType != 'Search External' && (
                                      <Select.Option value={'Recording'} key={'Recordings'}>
                                        {t('recordings')}
                                      </Select.Option>
                                    )}
                                    {type != 'Round Rails' && type != 'Wide Rails' && contentType != 'External' && contentType != 'Search External' && (
                                      <Select.Option value={'Movies'} key={'Movies'}>
                                        {t('movies')}
                                      </Select.Option>
                                    )}
                                    {type != 'Round Rails' && type != 'Rectangle Rails' && contentType != 'External' && contentType != 'Search External' && (
                                      <Select.Option value={'Series'} key={'Series'}>
                                        {t('series')}
                                      </Select.Option>
                                    )}
                                    {type != 'Banner Rails' && type != 'Morphing Rails' && type != 'Widescreen Rails' && type != 'Player' && (
                                      <Select.Option value={'Shorts'} key={'Shorts'}>
                                        {t('shorts')}
                                      </Select.Option>
                                    )}
                                    {type != 'Banner Rails' && contentType != 'Downloads' && type != 'Player Rails' && type != 'Morphing Rails' && type != 'Rectangle Rails' && type != 'Wide Rails' && type != 'Player' && contentType != 'External' && contentType != 'Search External' && (
                                      <Select.Option value={'Music'} key={'Music'}>
                                        {t('music')}
                                      </Select.Option>
                                    )}
                                    {type != 'Banner Rails' && contentType != 'Downloads' && type != 'Player Rails' && type != 'Morphing Rails' && type != 'Rectangle Rails' && type != 'Wide Rails' && type != 'Player' && contentType != 'External' && contentType != 'Search External' && (
                                      <Select.Option value={'Podcasts'} key={'Podcasts'}>
                                        {t('podcasts')}
                                      </Select.Option>
                                    )}
                                    {type != 'Banner Rails' && type != 'Player Rails' && type != 'Morphing Rails' && type != 'Round Rails' && type != 'Rectangle Rails' && contentType != 'External' && contentType != 'Search External' && (
                                      <Select.Option value={'Courses'} key={'Ecucation'}>
                                        {t('courses')}
                                      </Select.Option>
                                    )}
                                  </Select>
                                </Form.Item>
                              )}
                              {(type == 'Single' || type == 'Slider' || type == 'Player') && (
                                <Form.Item name={['list', 'type']} className="form-group pb-0">
                                  <Select onChange={setContentListTypeLocal} className="default-select" placeholder="- Select Type -">
                                    <Select.Option value={'Channels'} key={'Channels'}>
                                      {t('channels')}
                                    </Select.Option>
                                    <Select.Option value={'Movies'} key={'Movies'}>
                                      {t('movies')}
                                    </Select.Option>
                                    <Select.Option value={'Series'} key={'Series'}>
                                      {t('series')}
                                    </Select.Option>
                                    <Select.Option value={'Courses'} key={'Ecucation'}>
                                      {t('courses')}
                                    </Select.Option>
                                  </Select>
                                </Form.Item>
                              )}
                            </div>
                          </div>
                        )}
                        {contentType != 'InAppSeries' && contentType != 'InAppCourses' && contentType != '' && contentType != 'AppsAndroidTV' && (
                          <div className="row">
                            <label className="col-md-4 col-form-label">{t('content-type')}</label>
                            <div className="col" style={{ paddingLeft: 0 }}>
                              {type != 'Single' && type != 'Slider' && type != 'Player' && (
                                <Form.Item name={['list', 'type']} className="form-group pb-0">
                                  <Select onChange={setContentListTypeLocal} className="default-select" placeholder="- Select Type -">
                                    {type != 'Banner Rails' && contentType != 'Downloads' && type != 'Morphing Rails' && type != 'Rectangle Rails' && contentType != 'External' && contentType != 'Search External' && (
                                      <Select.Option value={'Channels'} key={'Channels'}>
                                        {t('channels')}
                                      </Select.Option>
                                    )}
                                    {type != 'Banner Rails' && contentType != 'Downloads' && type != 'Player Rails' && type != 'Morphing Rails' && type != 'Rectangle Rails' && type != 'Wide Rails' && type != 'Player' && contentType != 'External' && contentType != 'Search External' && (
                                      <Select.Option value={'Radio'} key={'Radio'}>
                                        {t('radio')}
                                      </Select.Option>
                                    )}
                                    {type != 'Banner Rails' && contentType != 'Downloads' && type != 'Morphing Rails' && type != 'Rectangle Rails' && contentType != 'External' && contentType != 'Search External' && (
                                      <Select.Option value={'Cams'} key={'Cams'}>
                                        {t('cams')}
                                      </Select.Option>
                                    )}

                                    {type != 'Banner Rails' && contentType != 'Downloads' && type != 'Player Rails' && data.type != 'Genre Rails' && type != 'Morphing Rails' && type != 'Rectangle Rails' && type != 'Round Rails' && type != 'Rectangle Rails' && type != 'Player' && contentType != 'External' && contentType != 'Search External' && (
                                      <Select.Option value={'CatchupTV'} key={'CatchupTV'}>
                                        CatchupTV
                                      </Select.Option>
                                    )}
                                    {type != 'Banner Rails' && contentType != 'Downloads' && type != 'Player Rails' && data.type != 'Genre Rails' && type != 'Morphing Rails' && type != 'Rectangle Rails' && type != 'Round Rails' && type != 'Rectangle Rails' && type != 'Player' && contentType != 'External' && contentType != 'Search External' && (
                                      <Select.Option value={'Recording'} key={'Recordings'}>
                                        {t('recordings')}
                                      </Select.Option>
                                    )}
                                    {type != 'Round Rails' && type != 'Wide Rails' && contentType != 'External' && contentType != 'Search External' && (
                                      <Select.Option value={'Movies'} key={'Movies'}>
                                        {t('movies')}
                                      </Select.Option>
                                    )}
                                    {type != 'Morphing Rails' && type != 'Round Rails' && type != 'Rectangle Rails' && contentType != 'External' && contentType != 'Search External' && (
                                      <Select.Option value={'Series'} key={'Series'}>
                                        {t('series')}
                                      </Select.Option>
                                    )}
                                    {type != 'Banner Rails' && type != 'Morphing Rails' && type != 'Widescreen Rails' && type != 'Player' && (
                                      <Select.Option value={'Shorts'} key={'Shorts'}>
                                        {t('shorts')}
                                      </Select.Option>
                                    )}
                                    {type != 'Banner Rails' && contentType != 'Downloads' && type != 'Player Rails' && type != 'Morphing Rails' && type != 'Rectangle Rails' && type != 'Wide Rails' && type != 'Player' && contentType != 'External' && contentType != 'Search External' && (
                                      <Select.Option value={'Music'} key={'Music'}>
                                        {t('music')}
                                      </Select.Option>
                                    )}
                                    {type != 'Banner Rails' && contentType != 'Downloads' && type != 'Player Rails' && type != 'Morphing Rails' && type != 'Rectangle Rails' && type != 'Wide Rails' && type != 'Player' && contentType != 'External' && contentType != 'Search External' && (
                                      <Select.Option value={'Podcasts'} key={'Podcasts'}>
                                        {t('podcasts')}
                                      </Select.Option>
                                    )}
                                    {type != 'Banner Rails' && type != 'Player Rails' && type != 'Morphing Rails' && type != 'Round Rails' && type != 'Rectangle Rails' && contentType != 'External' && contentType != 'Search External' && (
                                      <Select.Option value={'Courses'} key={'Ecucation'}>
                                        {t('courses')}
                                      </Select.Option>
                                    )}
                                    {type != 'Banner Rails' && contentType != 'Downloads' && type != 'Player Rails' && type != 'Morphing Rails' && type != 'Round Rails' && type != 'Rectangle Rails' && contentType != 'External' && contentType != 'Search External' && (
                                      <Select.Option value={'Games'} key={'Games'}>
                                        {t('games')}
                                      </Select.Option>
                                    )}
                                  </Select>
                                </Form.Item>
                              )}
                              {(type == 'Single' || type == 'Slider' || type == 'Player') && (
                                <Form.Item name={['list', 'type']} className="form-group pb-0">
                                  <Select onChange={setContentListTypeLocal} className="default-select" placeholder="- Select Type -">
                                    <Select.Option value={'Channels'} key={'Channels'}>
                                      {t('channels')}
                                    </Select.Option>
                                    <Select.Option value={'Movies'} key={'Movies'}>
                                      {t('movies')}
                                    </Select.Option>
                                    <Select.Option value={'Series'} key={'Series'}>
                                      {t('series')}
                                    </Select.Option>
                                    <Select.Option value={'Courses'} key={'Ecucation'}>
                                      {t('courses')}
                                    </Select.Option>
                                  </Select>
                                </Form.Item>
                              )}
                            </div>
                          </div>
                        )}

                        {contentType == 'Content List' && data.type != 'Genre Rails' && listType != '' && (
                          <div className="row">
                            <label className="col-md-4 col-form-label">{t('content-list')}</label>
                            <div className="col" style={{ paddingLeft: 0 }}>
                              <Form.Item name={['list', 'list']} className="form-group pb-0">
                                <Select onChange={setListLocal} className="default-select" placeholder="- Select Type -">
                                  {contentList.map((list: any) => {
                                    return (
                                      <Select.Option value={list._id} key={list._id}>
                                        {list.name}
                                      </Select.Option>
                                    );
                                  })}
                                </Select>
                              </Form.Item>
                            </div>
                          </div>
                        )}
                      </div>

                      {contentType != 'AppsAndroidTV' && data.type != 'Banner Rails' && data.type != 'Wide Player Rails' && data.type != 'Single' && data.type != 'Slider' && data.type != 'Player' && contentType == 'Content List' && listType != 'Music' && listType != 'Shorts' && listType != 'Cams' && listType != 'Radio' && listType != 'Podcasts' && listType != 'CatchupTV' && listType != 'Recordings' && (
                        <div className="row" style={{ padding: 15 }}>
                          <div className="col card-bg-light p-4 mb-2 mr-1" style={{ paddingLeft: 0 }}>
                            <h5 className="text-primary">{t('link-to-hero')}</h5>
                            <p>{t('when-enabled-and-when-the-player-hero-component-is-utilized-the-content-from-this-rails-will-be-employed-to-display-a-preview-within-the-player-hero-component')}</p>
                            <div className="row">
                              <label className="col-md-4 col-form-label">{t('enable-linking')}</label>
                              <div className="col">
                                <Form.Item className="form-group pb-0 " name="content.link_to_hero">
                                  <Switch className="ch-switch" checked={linkToHero} defaultChecked={false} onChange={() => setLinkToHero(!linkToHero)} />
                                </Form.Item>
                              </div>
                              <div className="col"></div>
                              <div className="col"></div>
                            </div>
                          </div>
                        </div>
                      )}

                      {contentType != 'AppsAndroidTV' && data.type != 'Banner Rails' && data.type != 'Genre Rails' && data.type != 'Single' && data.type != 'Slider' && data.type != 'Player' && (
                        <div className="row" style={{ padding: 15 }}>
                          <div className="col card-bg-light p-4 mb-2 mr-1" style={{ paddingLeft: 0 }}>
                            <h5 className="text-primary">{t('press-and-hold')}</h5>
                            <p>{t('enabling-press-and-hold-will-grant-your-customers-the-ability-to-manage-their-watchlist-and-favorites-through-a-compact-popup-window')}</p>
                            <div className="row">
                              <label className="col-md-4 col-form-label">{t('enable-press-and-hold')}</label>
                              <div className="col">
                                <Form.Item className="form-group pb-0 " name="content.link_to_hero">
                                  <Switch className="ch-switch" checked={pressAndHold} defaultChecked={false} onChange={() => setPressAndHold(!pressAndHold)} />
                                </Form.Item>
                              </div>
                              <div className="col"></div>
                              <div className="col"></div>
                            </div>
                            <div className="row">
                              <label className="col-md-4 col-form-label">{t('show-information')}</label>
                              <div className="col">
                                <Form.Item className="form-group pb-0 " name="content.link_to_hero">
                                  <Switch className="ch-switch" checked={pressAndHoldMessage} defaultChecked={false} onChange={() => setPressAndHoldMessage(!pressAndHoldMessage)} />
                                </Form.Item>
                              </div>
                              <div className="col"></div>
                              <div className="col"></div>
                            </div>
                          </div>
                        </div>
                      )}

                      {contentType != 'AppsAndroidTV' && data.type != 'Banner Rails' && listType != 'Series' && listType != 'Courses' && data.type != 'Genre Rails' && contentType != 'Search' && listType != '' && (
                        <div className="row" style={{ padding: 15 }}>
                          <div className="col card-bg-light p-4 mb-2 mr-1" style={{ paddingLeft: 0 }}>
                            <h5 className="text-primary">{t('direct-to-player')}</h5>
                            <p>{t('when-enabled-clicking-the-content-item-within-this-rail-will-directly-take-you-to-the-player-bypassing-the-details-page')}</p>
                            <div className="row">
                              <label className="col-md-4 col-form-label">{t('skip-details')}</label>
                              <div className="col">
                                <Form.Item className="form-group pb-0 " name="content.play_directly)">
                                  <Switch className="ch-switch" checked={directToPlayer} defaultChecked={false} onChange={() => setDirectToPlayer(!directToPlayer)} />
                                </Form.Item>
                              </div>
                              <div className="col"></div>
                              <div className="col"></div>
                            </div>
                          </div>
                        </div>
                      )}
                      {contentType != 'AppsAndroidTV' && listType != 'Movies' && listType != 'Series' && listType != '' && (
                        <div className="row" style={{ padding: 15 }}>
                          <div className="col card-bg-light p-4 mb-2 mr-1" style={{ paddingLeft: 0 }}>
                            <h5 className="text-primary">{t('use-deeplinking')}</h5>
                            <p>{t('with-deeplinking-you-can-have-your-app-open-or-play-content-on-a-3rd-party-app')}</p>
                            <div className="row">
                              <label className="col-md-4 col-form-label">{t('enable-deeplinking')}</label>
                              <div className="col">
                                <Form.Item className="form-group pb-0 " name="content.enable_deeplinking)">
                                  <Switch className="ch-switch" checked={enableDeeplinking} defaultChecked={false} onChange={() => setEnableDeeplinking(!enableDeeplinking)} />
                                </Form.Item>
                              </div>
                              <div className="col"></div>
                              <div className="col"></div>
                            </div>
                          </div>
                        </div>
                      )}

                      {type != 'Banner Rails' && data.type != 'Single' && data.type != 'Slider' && data.type != 'Player' && (
                        <div className="col card-bg-light p-4 mb-2 mr-1" style={{ paddingLeft: 0 }}>
                          <h5 className="text-primary">{t('rails-texts-and-image')}</h5>
                          <p> </p>

                          <div className="row pt-2">
                            <label className="col-md-4 col-form-label">{t('show-rails-texts-and-image')}</label>

                            <div className="col">
                              <Form.Item className="form-group pb-0 " name={['list', 'show_title']}>
                                <Switch className="ch-switch" checked={showTitle} defaultChecked={showTitle} onChange={() => setShowTitle(!showTitle)} />
                              </Form.Item>
                            </div>
                            <div className="col"></div>
                            <div className="col"></div>
                          </div>
                          <div className="row pt-2">
                            <label className="col-md-4 col-form-label">{t('enable-overview-page')}</label>

                            <div className="col">
                              <Form.Item className="form-group pb-0 " name={['list', 'enable_overview']}>
                                <Switch className="ch-switch" checked={enableOverview} defaultChecked={enableOverview} onChange={() => setEnableOverview(!enableOverview)} />
                              </Form.Item>
                            </div>
                            <div className="col"></div>
                            <div className="col"></div>
                          </div>

                          <div className="row p-3">
                            <div className="col" style={{ paddingLeft: 0, paddingRight: 0 }}>
                              <h5 className="text-primary">{t('title')}</h5>
                              <Translations setTranslations={setTranslationsLocalTitle} data={translationsTitle}></Translations>
                            </div>
                          </div>
                          <div className="row p-3">
                            <div className="col" style={{ paddingLeft: 0, paddingRight: 0 }}>
                              <h5 className="text-primary">{t('tagline')}</h5>
                              <Translations setTranslations={setTranslationsLocalTagline} data={translationsTagline}></Translations>
                            </div>
                          </div>
                          <div className="row" style={{}}>
                            <div className="col card-bg-light p-4 mb-2 mr-1" style={{ paddingLeft: 0 }}>
                              <div className="row-md-12">
                                <ImageUploader
                                  onSubmit={(values: any) => {
                                    setImageUrl(values);
                                  }}
                                  initialData={{
                                    client: clientName,
                                    deployment: deployment,
                                    service: undefined,
                                    notMandatory: true,
                                    uploadedImage: imageUrl,
                                    uploadName: t('rails_square'),
                                    uploadType: 'square',
                                    path: STORAGE_ENDPOINTS(clientName, deployment?.name, '').APPBUILDER.IMAGES,
                                  }}
                                />
                              </div>
                            </div>
                          </div>
                          <div className="row" style={{}}>
                            <div className="col card-bg-light p-4 mb-2 mr-1" style={{ paddingLeft: 0 }}>
                              <div className="row-md-12">
                                <ImageUploader
                                  onSubmit={(values: any) => {
                                    setImageBackgroundLandscapeUrl(values);
                                  }}
                                  initialData={{
                                    client: clientName,
                                    deployment: deployment,
                                    service: undefined,
                                    uploadedImage: imageBackgroundLandscapeUrl,
                                    uploadName: t('overview_background_landscape'),
                                    uploadType: 'landscape',
                                    notMandatory: true,
                                    path: STORAGE_ENDPOINTS(clientName, deployment?.name, '').APPBUILDER.IMAGES,
                                  }}
                                />
                              </div>
                            </div>
                          </div>
                          <div className="row" style={{}}>
                            <div className="col card-bg-light p-4 mb-2 mr-1" style={{ paddingLeft: 0 }}>
                              <div className="row-md-12">
                                <ImageUploader
                                  onSubmit={(values: any) => {
                                    setImageBackgroundPortraitUrl(values);
                                  }}
                                  initialData={{
                                    client: clientName,
                                    deployment: deployment,
                                    service: undefined,
                                    notMandatory: true,
                                    uploadedImage: imageBackgroundPortraitUrl,
                                    uploadName: t('overview_background_portrait'),
                                    uploadType: 'portrait',
                                    path: STORAGE_ENDPOINTS(clientName, deployment?.name, '').APPBUILDER.IMAGES,
                                  }}
                                />
                              </div>
                            </div>
                          </div>
                        </div>
                      )}
                    </div>
                  </div>
                  <div className="col-md-12 p-0 mt-3">
                    <button type="submit" className="btn btn-secondary float-right rounded-pill mt-1 width-240 height-48">
                      {t('update-content')}
                    </button>
                  </div>
                </Form>
              </div>
            </div>
          </div>
        </div>
      </div>
    </Modal>
  );
};

export default LayoutModel;
