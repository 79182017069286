import { Route, Switch, useRouteMatch } from 'react-router-dom';
import DashboardDesignList from '../dashboarddesigner/list';
import AddDashboardDesign from './add';
import DetailsDashboardDesign from './details';

const DashboardDesignerIndex = () => {
  const { path } = useRouteMatch();

  return (
    <Switch>
      <Route path={`${path}/add`}>
        <AddDashboardDesign />
      </Route>
      <Route path={`${path}/details/:_id`}>
        <DetailsDashboardDesign />
      </Route>
      <Route path={`${path}`}>
        <DashboardDesignList />
      </Route>
    </Switch>

  );
};

export default DashboardDesignerIndex;
