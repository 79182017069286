import { faArrowsUpDown, faMemo, faTrashCan } from '@fortawesome/pro-regular-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Button, Modal, Table, Tooltip, Image, Col } from 'antd';
import _ from 'lodash';
import { useEffect, useState } from 'react';
import SongModal from './SongModal';
import ReactDragListView from 'react-drag-listview';
import PodcastModal from './PodcastModal';
import LessonModel from './LessonModel';
import EpisodeModel from './EpisodeModel';
import { useTranslation } from 'react-i18next';

const SubContent = (params: { setData: any; type: string; data?: any; onUpdate?: any }) => {
  const [showModal, setShowModal] = useState(false);
  const [dataLocal, setDataLocal] = useState([] as any);
  const [selected, setSelected] = useState([] as any);
  const [index, setIndex] = useState(99999);
  const [loading, setLoading] = useState(false);
  const { t } = useTranslation();
  let { confirm } = Modal;

  const handleAddSubmit = async (values: any) => {
    setLoading(true);
    if (values.update == false) {
      let previous_state: any[] = dataLocal; // params.data;
      previous_state.push(values);
      setShowModal(false);
      setDataLocal(previous_state);
      params.setData(previous_state);
      if (params.onUpdate != undefined) {
        params.onUpdate(previous_state);
      }
    } else {
      params.data[index] = values;
      console.log(index);
      if (params.onUpdate != undefined) {
        console.log('onUpdate');
        params.onUpdate(params.data);
      }
      setDataLocal(params.data);
      setShowModal(false);
    }
    setLoading(false);
  };
  const handleDelete = (row: any) => {
    let previous_state: any[] = dataLocal;
    previous_state = previous_state.filter((song) => (song._id !== row._id ? song : undefined));
    if (previous_state.length > 0) {
      let index = _.findIndex(previous_state, {
        add_to_top: true,
      });
      if (index !== -1)
        previous_state = previous_state.filter((song, i) => {
          if (index !== i) song.add_to_top = false;
          return song;
        });
      else previous_state[0].add_to_top = true;
    }
    setDataLocal(previous_state);
    params.setData(previous_state);
    if (params.onUpdate != undefined) {
      params.onUpdate(previous_state);
    }
  };

  useEffect(() => {
    if (params.data != undefined) {
      setDataLocal(params.data);
    }
  }, [params.data]);

  //const that = this;
  const dragProps = {
    onDragEnd(fromIndex: any, toIndex: any) {
      const data = [...dataLocal];
      const item = data.splice(fromIndex, 1)[0];
      data.splice(toIndex, 0, item);
      setDataLocal(data);
      params.setData(data);
    },
    lineClassName: 'global-drag-line',
    handleSelector: '.drag-handle',
  };

  return (
    <div>
      <h4 className="text-primary mt-5 pb-2">{params.type}</h4>
      <div className="row">
        <div className="col-md-12">
          {params.type == 'Songs' && showModal && (
            <SongModal
              visible={showModal}
              onSubmit={(values) => handleAddSubmit({ ...values, key: 0 })}
              onCancel={() => {
                setShowModal(false);
              }}
              reset={false}
              dataIn={selected}
            />
          )}
          {params.type == 'Podcast Episodes' && showModal && (
            <PodcastModal
              visible={showModal}
              onSubmit={(values) => handleAddSubmit({ ...values, key: 0 })}
              onCancel={() => {
                setShowModal(false);
              }}
              reset={false}
              dataIn={selected}
            />
          )}
          {params.type == 'Lessons' && showModal && (
            <LessonModel
              visible={showModal}
              onSubmit={(values) => handleAddSubmit({ ...values, key: 0 })}
              onCancel={() => {
                setShowModal(false);
              }}
              reset={false}
              dataIn={selected}
              index={index}
            />
          )}
          {params.type == 'Season Episodes' && showModal && (
            <EpisodeModel
              visible={showModal}
              onSubmit={(values) => handleAddSubmit({ ...values, key: 0 })}
              onCancel={() => {
                setShowModal(false);
              }}
              reset={false}
              dataIn={selected}
              index={index}
            />
          )}
          <div className="">
            <Button
              className="mt-4 mb-4 ant-btn btn btn-secondary rounded-pill width-240 height-48 float-right"
              onClick={() => {
                setSelected([]);
                setShowModal(true);
              }}
            >
              {t('add')} {params.type}
            </Button>
          </div>
          <ReactDragListView {...dragProps}>
            {(params.type == 'Songs' || params.type == 'Podcast Episodes') && (
              <Table
                loading={loading}
                rowKey={(dataLocal) => dataLocal.name}
                columns={[
                  {
                    title: t('drag-and-drop'),
                    key: 'operate',
                    width: 100,
                    render: (text, record, index) => (
                      <div className="text-center">
                        <FontAwesomeIcon className="action-icon drag-handle" color={'#999'} size={'xl'} icon={faArrowsUpDown} />
                      </div>
                    ),
                  },

                  {
                    title: t('name'),
                    dataIndex: 'name',
                    key: 'name',
                  },
                  {
                    title: t('stream'),
                    dataIndex: params.type == 'Songs' ? ['streams', 'albums', 'url'] : params.type == 'Podcast Episodes' ? ['streams', 'podcasts', 'url'] : ['streams', 'course', 'url'],
                    key: 'streams',
                  },
                  {
                    title: t('duration'),
                    dataIndex: 'duration',
                    key: 'duration',
                  },
                  {
                    title: t('actions'),
                    dataIndex: 'action',
                    width: 100,
                    render: (tags: any, row: any, index_: any) => (
                      <span className="justify-content-end" style={{ height: 60 }}>
                        <Tooltip placement="top" title={`Delete`}>
                          <Button
                            onClick={() => {
                              confirm({
                                title: t('are-you-sure-you-want-to-delete-this-type', { type: params.type }),
                                okText: t('yes'),
                                okType: 'danger',
                                cancelText: t('no'),
                                onOk() {
                                  handleDelete(row);
                                },
                                onCancel() {},
                              });
                            }}
                            className="mx-2 cursor-pointer"
                            type="text"
                          >
                            <FontAwesomeIcon className="action-icon fa-trash-icon" icon={faTrashCan} />
                            <div>{t('delete')}</div>
                          </Button>
                        </Tooltip>
                        <Tooltip placement="top" title={`Edit`}>
                          <Button
                            className="mx-2 cursor-pointer"
                            onClick={() => {
                              setIndex(index_);
                              setSelected(row);
                              setShowModal(true);
                            }}
                            type="text"
                          >
                            <FontAwesomeIcon className="action-icon" icon={faMemo} />
                            <div>{t('open-details')}</div>
                          </Button>
                        </Tooltip>
                      </span>
                    ),
                  },
                ]}
                pagination={{
                  position: ['bottomLeft'],
                  pageSize: 10,
                  hideOnSinglePage: true,
                  showTotal: (total, range) => {
                    return t('range-0-range-1-of-total-items', { start: range[0], end: range[1], total: total });
                  },
                }}
                dataSource={dataLocal}
                key={JSON.stringify(dataLocal)}
              />
            )}
            {(params.type == 'Season Episodes' || params.type == 'Lessons') && (
              <Table
                loading={loading}
                rowKey={(dataLocal) => dataLocal.name}
                columns={[
                  {
                    title: t('drag-and-drop'),
                    key: 'operate',
                    width: 100,
                    render: (text, record, index) => (
                      <div className="text-center">
                        <FontAwesomeIcon className="action-icon drag-handle" color={'#999'} size={'xl'} icon={faArrowsUpDown} />
                      </div>
                    ),
                  },
                  {
                    title: t('image'),
                    dataIndex: ['images', 'widescreen'],
                    key: 'image',
                    width: 100,
                    render: (row) => (
                      <div className="text-center">
                        <Image width="200px" src={'https://cloudtv.akamaized.net/' + row}></Image>
                      </div>
                    ),
                  },

                  {
                    title: t('number'),
                    dataIndex: 'number',
                    key: 'number',
                  },
                  {
                    title: t('name'),
                    dataIndex: 'name',
                    key: 'name',
                  },
                  {
                    title: t('duration'),
                    dataIndex: 'duration',
                    key: 'duration',
                  },
                  {
                    title: t('actions'),
                    dataIndex: 'action',
                    width: 100,
                    render: (tags: any, row: any, index_: any) => (
                      <Col style={{ display: 'flex', flexDirection: 'row', height: 60 }}>
                        <Tooltip placement="top" title={`Delete`}>
                          <Button
                            onClick={() => {
                              confirm({
                                title: t('are-you-sure-you-want-to-delete-this-type', { type: params.type }),
                                okText: t('yes'),
                                okType: 'danger',
                                cancelText: t('no'),
                                onOk() {
                                  handleDelete(row);
                                },
                                onCancel() {},
                              });
                            }}
                            className="mx-2 cursor-pointer"
                            type="text"
                          >
                            <FontAwesomeIcon className="action-icon fa-trash-icon" icon={faTrashCan} />
                            <div>{t('delete')}</div>
                          </Button>
                        </Tooltip>
                        <Tooltip placement="top" title={`Edit`}>
                          <Button
                            className="mx-2 cursor-pointer"
                            onClick={() => {
                              setIndex(index_);
                              setSelected(row);
                              setShowModal(true);
                            }}
                            type="text"
                          >
                            <FontAwesomeIcon className="action-icon" icon={faMemo} />
                            <div>{t('open-details')}</div>
                          </Button>
                        </Tooltip>
                      </Col>
                    ),
                  },
                ]}
                pagination={{
                  position: ['bottomLeft'],
                  pageSize: 1000,
                  hideOnSinglePage: true,
                  showTotal: (total, range) => {
                    return `${range[0]}-${range[1]} of ${total} items`;
                  },
                }}
                dataSource={dataLocal}
                key={JSON.stringify(dataLocal)}
              />
            )}
          </ReactDragListView>
        </div>
      </div>
    </div>
  );
};
export default SubContent;
