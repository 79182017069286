import axiosInstance from '../../../utils/axios';
import { API_ENDPOINTS } from '../../_APIConstants';
import { AppStoresProvider } from '../../../interfaces/forms/areas/billing/appstoresProvider';
import { AxiosResponse } from 'axios';
import { useMutation, UseMutationOptions, useQuery } from '@tanstack/react-query';

const { BILLING: BILLING_ENDPONTS } = API_ENDPOINTS;
const { APPSTORES_PROVIDERS: ENDPOINTS } = BILLING_ENDPONTS;

type Params = { instance: string; cms: string; crm: string; path?: string };

type PaginationParams = {
  page: number;
  limit: number;
};

type ResponseFormat<T> = {
  docs: T;
};

type PaginatedResults<T> = {
  hasNextPage: boolean;
  hasPrevPage: boolean;
  limit: number;
  nextPage: number | null;
  page: number;
  pagingCounter: number;
  prevPage: null | number;
  totalDocs: number;
  totalPages: number;
} & ResponseFormat<T>;

export const list = ({ params }: { params: Params & PaginationParams }): Promise<AxiosResponse<PaginatedResults<AppStoresProvider[]>>> => {
  return axiosInstance.get<PaginatedResults<AppStoresProvider[]>>(ENDPOINTS.LIST, { params });
};

export type AddParams = { params: Params } & { data: AppStoresProvider };

export const add = ({ data, params }: AddParams): Promise<AxiosResponse<AppStoresProvider>> => {
  return axiosInstance.post<AppStoresProvider>(ENDPOINTS.ADD, data, { params });
};

export type PatchParams = { params: Params } & { data: Partial<AppStoresProvider>; id: string };

export const patch = ({ data, params, id }: PatchParams): Promise<AxiosResponse<AppStoresProvider>> => {
  return axiosInstance.patch<AppStoresProvider>(`${ENDPOINTS.PATCH}/${id}`, data, { params });
};

export type PutParams = { params: Params } & { data: AppStoresProvider; id: string };

export const put = ({ data, params, id }: PutParams): Promise<AxiosResponse<AppStoresProvider>> => {
  return axiosInstance.put<AppStoresProvider>(`${ENDPOINTS.PUT}/${id}`, data, { params });
};

type DeleteParams = { params: Params; id: string };

export const del = ({ params, id }: DeleteParams): Promise<AxiosResponse> => {
  
  return axiosInstance.delete(`${ENDPOINTS.DELETE}/${id}`, { params });
};

type GetParams = { params: Params; id: string };

export const get = ({ params, id }: GetParams): Promise<AxiosResponse<AppStoresProvider>> => {
  return axiosInstance.get(`${ENDPOINTS.GET}/${id}`, { params });
};

export const useAppStoresProviders = ({ params }: { params: Params & PaginationParams }) =>
  useQuery(['appstore-providers', 'list', params], async () => {
    return list({ params })
      .then((r) => Promise.resolve(r))
      .catch((e) => Promise.reject(e));
  });

export const useAppStoresProviderAdd = ({ params }: Omit<AddParams, 'data'>, options: Omit<UseMutationOptions<AxiosResponse<AppStoresProvider>, unknown, AppStoresProvider, unknown>, 'mutationFn' | 'mutationKey'>) =>
  useMutation(
    ['appstore-providers', 'add', params.instance, params.crm],
    async (values: AppStoresProvider) => {
      return add({ data: values, params })
        .then((r) => Promise.resolve(r))
        .catch((e) => Promise.reject(e));
    }, 
    options
  );

export const useAppStoresProviderPut = ({ params, id }: Omit<PutParams, 'data'>, options: Omit<UseMutationOptions<AxiosResponse<AppStoresProvider>, unknown, AppStoresProvider, unknown>, 'mutationFn' | 'mutationKey'>) =>
  useMutation(
    ['appstore-providers', 'add', params.instance, params.crm],
    async (values: AppStoresProvider) => {
      return put({ data: values, params, id })
        .then((r) => Promise.resolve(r))
        .catch((e) => Promise.reject(e));
    },
    options
  );

export const useAppStoresProviderDelete = ({ params }: Omit<DeleteParams, 'id'>, options: Omit<UseMutationOptions<AxiosResponse<any>, unknown, string, unknown>, 'mutationFn' | 'mutationKey'>) =>
  useMutation(
    ['appstore-providers', 'add', params.instance, params.crm],
    async (id: string) => {
      return del({ params, id })
        .then((r) => Promise.resolve(r))
        .catch((e) => Promise.reject(e));
    },
    options
  );
