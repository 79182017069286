type UrlToMenuMapping = {
  areaRelativeUrlSegment: string;
  openKeys: string[];
  selectedKey: string;
}

const contentUrlToMenuMappings: UrlToMenuMapping[] = [
  { areaRelativeUrlSegment: "/transcoders/transcoders", openKeys: ["media", "channels"], selectedKey: "channels-transcoders" },
  { areaRelativeUrlSegment: "/actions/importer-excel", openKeys: ["import"], selectedKey: "content-import" },

  { areaRelativeUrlSegment: "/channels/channels", openKeys: ["media", "channels"], selectedKey: "channels-channels-channels" },
  { areaRelativeUrlSegment: "/channels/bundles", openKeys: ["media", "channels"], selectedKey: "channels-channels-bundles" },
  { areaRelativeUrlSegment: "/channels/lists", openKeys: ["media", "channels"], selectedKey: "channels-lists" },
  { areaRelativeUrlSegment: "/channels/curations", openKeys: ["media", "channels"], selectedKey: "channels-curations" },
  { areaRelativeUrlSegment: "/channels/providers", openKeys: ["media", "channels"], selectedKey: "channels-providers" },
  { areaRelativeUrlSegment: "/channels/settings", openKeys: ["media", "channels"], selectedKey: "channels-settings" },
  { areaRelativeUrlSegment: "/radios/radios", openKeys: ["media", "radio"], selectedKey: "radio-radios-radios" },
  { areaRelativeUrlSegment: "/radios/bundles", openKeys: ["media", "radio"], selectedKey: "radio-radios-bundles" },
  { areaRelativeUrlSegment: "/radios/lists", openKeys: ["media", "radio"], selectedKey: "radio-lists" },
  { areaRelativeUrlSegment: "/radios/curations", openKeys: ["media", "radio"], selectedKey: "radio-curations" },
  { areaRelativeUrlSegment: "/radios/providers", openKeys: ["media", "radio"], selectedKey: "radio-providers" },
  { areaRelativeUrlSegment: "/epg/epg_urls", openKeys: ["media", "tvguide"], selectedKey: "tvguide-urls" },
  { areaRelativeUrlSegment: "/epg/epg_linking", openKeys: ["media", "tvguide"], selectedKey: "tvguide-linking" },
  { areaRelativeUrlSegment: "/epg/epg_settings", openKeys: ["media", "tvguide"], selectedKey: "tvguide-settings" },
  { areaRelativeUrlSegment: "/cams/cams", openKeys: ["media", "cams"], selectedKey: "cams-cams" },
  { areaRelativeUrlSegment: "/cams/bundles", openKeys: ["media", "cams"], selectedKey: "cams-bundles" },
  { areaRelativeUrlSegment: "/cams/lists", openKeys: ["media", "cams"], selectedKey: "cams-lists" },
  { areaRelativeUrlSegment: "/cams/curations", openKeys: ["media", "cams"], selectedKey: "cams-curations" },
  { areaRelativeUrlSegment: "/cams/providers", openKeys: ["media", "cams"], selectedKey: "cams-providers" },
  { areaRelativeUrlSegment: "/cams/settings", openKeys: ["media", "cams"], selectedKey: "cams-settings" },
  { areaRelativeUrlSegment: "/movies/movies", openKeys: ["media", "movies"], selectedKey: "movies-movies" },
  { areaRelativeUrlSegment: "/movies/bundles", openKeys: ["media", "movies"], selectedKey: "movies-bundles" },
  { areaRelativeUrlSegment: "/movies/lists", openKeys: ["media", "movies"], selectedKey: "movies-lists" },
  { areaRelativeUrlSegment: "/movies/curations", openKeys: ["media", "movies"], selectedKey: "movies-curations" },
  { areaRelativeUrlSegment: "/movies/providers", openKeys: ["media", "movies"], selectedKey: "movies-providers" },
  { areaRelativeUrlSegment: "/movies/search", openKeys: ["media", "movies"], selectedKey: "movies-search" },
  { areaRelativeUrlSegment: "/movies/upload", openKeys: ["media", "movies"], selectedKey: "movies-upload" },
  { areaRelativeUrlSegment: "/games/games", openKeys: ["media", "games"], selectedKey: "games-games" },
  { areaRelativeUrlSegment: "/games/bundles", openKeys: ["media", "games"], selectedKey: "games-bundles" },
  { areaRelativeUrlSegment: "/games/lists", openKeys: ["media", "games"], selectedKey: "games-lists" },
  { areaRelativeUrlSegment: "/games/curations", openKeys: ["media", "games"], selectedKey: "games-curations" },
  { areaRelativeUrlSegment: "/games/providers", openKeys: ["media", "games"], selectedKey: "games-providers" },
  { areaRelativeUrlSegment: "/apps/apps", openKeys: ["media", "apps"], selectedKey: "apps-apps" },
  { areaRelativeUrlSegment: "/apps/bundles", openKeys: ["media", "apps"], selectedKey: "apps-bundles" },
  { areaRelativeUrlSegment: "/apps/lists", openKeys: ["media", "apps"], selectedKey: "apps-lists" },
  { areaRelativeUrlSegment: "/apps/curations", openKeys: ["media", "apps"], selectedKey: "apps-curations" },
  { areaRelativeUrlSegment: "/shorts/shorts", openKeys: ["media", "shorts"], selectedKey: "shorts-shorts" },
  { areaRelativeUrlSegment: "/shorts/bundles", openKeys: ["media", "shorts"], selectedKey: "shorts-bundles" },
  { areaRelativeUrlSegment: "/shorts/lists", openKeys: ["media", "shorts"], selectedKey: "shorts-lists" },
  { areaRelativeUrlSegment: "/shorts/curations", openKeys: ["media", "shorts"], selectedKey: "shorts-curations" },
  { areaRelativeUrlSegment: "/shorts/providers", openKeys: ["media", "shorts"], selectedKey: "shorts-providers" },
  { areaRelativeUrlSegment: "/shorts/upload", openKeys: ["media", "shorts"], selectedKey: "shorts-upload" },
  { areaRelativeUrlSegment: "/series/series", openKeys: ["media", "series"], selectedKey: "series-series" },
  { areaRelativeUrlSegment: "/series/seasons", openKeys: ["media", "series"], selectedKey: "series-seasons" },
  { areaRelativeUrlSegment: "/series/bundles", openKeys: ["media", "series"], selectedKey: "series-bundles" },
  { areaRelativeUrlSegment: "/series/lists", openKeys: ["media", "series"], selectedKey: "series-lists" },
  { areaRelativeUrlSegment: "/series/curations", openKeys: ["media", "series"], selectedKey: "series-curations" },
  { areaRelativeUrlSegment: "/series/providers", openKeys: ["media", "series"], selectedKey: "series-providers" },
  { areaRelativeUrlSegment: "/series/search", openKeys: ["media", "series"], selectedKey: "series-search" },
  { areaRelativeUrlSegment: "/series/upload", openKeys: ["media", "series"], selectedKey: "series-upload" },
  { areaRelativeUrlSegment: "/courses/courses", openKeys: ["media", "courses"], selectedKey: "course-course" },
  { areaRelativeUrlSegment: "/courses/bundles", openKeys: ["media", "courses"], selectedKey: "course-bundles" },
  { areaRelativeUrlSegment: "/courses/lists", openKeys: ["media", "courses"], selectedKey: "course-lists" },
  { areaRelativeUrlSegment: "/courses/curations", openKeys: ["media", "courses"], selectedKey: "course-curations" },
  { areaRelativeUrlSegment: "/courses/providers", openKeys: ["media", "courses"], selectedKey: "course-providers" },
  { areaRelativeUrlSegment: "/courses/upload", openKeys: ["media", "courses"], selectedKey: "course-upload" },
  { areaRelativeUrlSegment: "/albums/albums", openKeys: ["media", "albums"], selectedKey: "albums-albums" },
  { areaRelativeUrlSegment: "/albums/bundles", openKeys: ["media", "albums"], selectedKey: "albums-bundles" },
  { areaRelativeUrlSegment: "/albums/lists", openKeys: ["media", "albums"], selectedKey: "albums-lists" },
  { areaRelativeUrlSegment: "/albums/curations", openKeys: ["media", "albums"], selectedKey: "albums-curations" },
  { areaRelativeUrlSegment: "/albums/providers", openKeys: ["media", "albums"], selectedKey: "albums-providers" },
  { areaRelativeUrlSegment: "/albums/upload", openKeys: ["media", "albums"], selectedKey: "albums-upload" },
  { areaRelativeUrlSegment: "/podcasts/podcasts", openKeys: ["media", "podcasts"], selectedKey: "podcasts-podcasts" },
  { areaRelativeUrlSegment: "/podcasts/bundles", openKeys: ["media", "podcasts"], selectedKey: "podcasts-bundles" },
  { areaRelativeUrlSegment: "/podcasts/lists", openKeys: ["media", "podcasts"], selectedKey: "podcasts-lists" },
  { areaRelativeUrlSegment: "/podcasts/curations", openKeys: ["media", "podcasts"], selectedKey: "podcasts-curations" },
  { areaRelativeUrlSegment: "/podcasts/providers", openKeys: ["media", "podcasts"], selectedKey: "podcasts-providers" },
  { areaRelativeUrlSegment: "/podcasts/upload", openKeys: ["media", "podcasts"], selectedKey: "podcasts-upload" },
  { areaRelativeUrlSegment: "/providers/providers", openKeys: ["settings"], selectedKey: "providers" },
  { areaRelativeUrlSegment: "/tags/tags", openKeys: ["settings"], selectedKey: "tags" },
  { areaRelativeUrlSegment: "/nests/nests", openKeys: ["settings"], selectedKey: "nests" },
  { areaRelativeUrlSegment: "/params/params", openKeys: ["settings"], selectedKey: "params" },
  { areaRelativeUrlSegment: "/drm/buydrm", openKeys: ["drm-tokens"], selectedKey: "drm-tokens-buydrm" },
  { areaRelativeUrlSegment: "/drm/irdeto", openKeys: ["drm-tokens"], selectedKey: "drm-tokens-irdeto" },
  { areaRelativeUrlSegment: "/drm/settings", openKeys: ["drm-tokens"], selectedKey: "drm-tokens-settings" },
  { areaRelativeUrlSegment: "/ppv/ppvrules", openKeys: ["ppv-settings"], selectedKey: "ppv" },
];

const inventoryUrlToMenuMappings: UrlToMenuMapping[] = [
  { areaRelativeUrlSegment: "/packages", openKeys: ["inventory"], selectedKey: "products-pkcs" },
  { areaRelativeUrlSegment: "/services", openKeys: ["servcs"], selectedKey: "items-services" },
  { areaRelativeUrlSegment: "/settings/taxes", openKeys: ["txes"], selectedKey: "settings-taxes" },
];

const billingUrlToMenuMappings: UrlToMenuMapping[] = [
  { areaRelativeUrlSegment: "/shopping-carts", openKeys: ["shopping-carts"], selectedKey: "web-shops" },
  { areaRelativeUrlSegment: "/payment-gateways/my-gateways", openKeys: ["gateways", "payment-method"], selectedKey: "my-payment-methods" },
  { areaRelativeUrlSegment: "/payment-gateways", openKeys: ["gateways", "payment-method"], selectedKey: "payment-methods" },
  { areaRelativeUrlSegment: "/email-gateways/my-gateways", openKeys: ["gateways", "email-gateways"], selectedKey: "my-email-gateways" },
  { areaRelativeUrlSegment: "/email-gateways", openKeys: ["gateways", "email-gateways"], selectedKey: "email-gateway" },
  { areaRelativeUrlSegment: "/sms-gateways/my-gateways", openKeys: ["gateways", "sms-gateways"], selectedKey: "my-sms-gateways" },
  { areaRelativeUrlSegment: "/sms-gateways", openKeys: ["gateways", "sms-gateways"], selectedKey: "sms-gateway" },
  { areaRelativeUrlSegment: "/push-gateways/my-gateways", openKeys: ["gateways", "push-gateways"], selectedKey: "my-push-gateways" },
  { areaRelativeUrlSegment: "/push-gateways", openKeys: ["gateways", "push-gateways"], selectedKey: "push-gateway" },
  { areaRelativeUrlSegment: "/settings/sms-templates", openKeys: ["templates"], selectedKey: "settings-sms-templates" },
  { areaRelativeUrlSegment: "/settings/email-templates", openKeys: ["templates"], selectedKey: "settings-email-templates" },
  { areaRelativeUrlSegment: "/settings/app-templates", openKeys: ["templates"], selectedKey: "settings-app-templates" },
  { areaRelativeUrlSegment: "/settings/push-templates", openKeys: ["templates"], selectedKey: "settings-push-templates" },
  { areaRelativeUrlSegment: "/settings/welcome-message", openKeys: ["messages"], selectedKey: "settings-welcomeMessage" },
  { areaRelativeUrlSegment: "/settings/registration", openKeys: ["messages"], selectedKey: "settings-registration" },
  { areaRelativeUrlSegment: "/settings/password-recovery-message", openKeys: ["messages"], selectedKey: "settings-passwordRecovery" },
  { areaRelativeUrlSegment: "/settings/renewals", openKeys: ["messages"], selectedKey: "settings-renewals" },
  { areaRelativeUrlSegment: "/settings/dunning", openKeys: ["messages"], selectedKey: "settings-dunning" },
  { areaRelativeUrlSegment: "/settings/promotions", openKeys: ["messages"], selectedKey: "settings-promotions" },
  { areaRelativeUrlSegment: "/settings/payment-received-message", openKeys: ["messages"], selectedKey: "settings-paymentReceivedMessage" },
  { areaRelativeUrlSegment: "/settings/payment-failed-message", openKeys: ["messages"], selectedKey: "settings-paymentFailedMessage" },
  { areaRelativeUrlSegment: "/settings/change-base-subscription-message", openKeys: ["messages"], selectedKey: "settings-changeBaseSubscriptionMessage" },
  { areaRelativeUrlSegment: "/settings/add-extra-subscription-message", openKeys: ["messages"], selectedKey: "settings-addExtraSubscriptionMessage" },
  { areaRelativeUrlSegment: "/settings/remove-extra-subscription-message", openKeys: ["messages"], selectedKey: "settings-removeExtraSubscriptionMessage" },
  { areaRelativeUrlSegment: "/settings/renewed-subscription-message", openKeys: ["messages"], selectedKey: "settings-renewExtraSubscriptionMessage" },
  { areaRelativeUrlSegment: "/settings/subscription-rules", openKeys: ["settings"], selectedKey: "settings-subscription-rules" },
  { areaRelativeUrlSegment: "/settings/translations", openKeys: ["settings"], selectedKey: "settings-translations" },
  { areaRelativeUrlSegment: "/apibuilder", openKeys: ["billing-api"], selectedKey: "billing-api-builder" },
  { areaRelativeUrlSegment: "/apis/v1", openKeys: ["billing-api"], selectedKey: "billing-api-add" },
  { areaRelativeUrlSegment: "/apis/mybundle", openKeys: ["billing-api"], selectedKey: "billing-api-mybundle" },
];

const customersUrlToMenuMappings: UrlToMenuMapping[] = [
  { areaRelativeUrlSegment: "/customers/list", openKeys: ["customers"], selectedKey: "customers-customers" },
  { areaRelativeUrlSegment: "/actions/importer-excel", openKeys: ["customers"], selectedKey: "customers-import" },
  { areaRelativeUrlSegment: "/tickets", openKeys: ["tickets"], selectedKey: "ticketspage" },
];

const marketingUrlToMenuMappings: UrlToMenuMapping[] = [
  { areaRelativeUrlSegment: "/affiliates/list", openKeys: ["affiliates"], selectedKey: "affiliates_" },
  { areaRelativeUrlSegment: "/coupons/list", openKeys: ["coupons"], selectedKey: "coupons_" },
  { areaRelativeUrlSegment: "/email-gateways/my-gateways", openKeys: ["gateways", "email-gateways"], selectedKey: "my-email-gateways" },
  { areaRelativeUrlSegment: "/email-gateways", openKeys: ["gateways", "email-gateways"], selectedKey: "email-gateway" },
  { areaRelativeUrlSegment: "/emsmsail-gateways/my-gateways", openKeys: ["gateways", "sms-gateways"], selectedKey: "my-sms-gateways" },
  { areaRelativeUrlSegment: "/sms-gateways", openKeys: ["gateways", "sms-gateways"], selectedKey: "sms-gateway" },
  { areaRelativeUrlSegment: "/push-gateways/my-gateways", openKeys: ["gateways", "push-gateways"], selectedKey: "my-push-gateways" },
  { areaRelativeUrlSegment: "/push-gateways", openKeys: ["gateways", "push-gateways"], selectedKey: "push-gateway" },
];

const reportingUrlToMenuMappings: UrlToMenuMapping[] = [
  { areaRelativeUrlSegment: "/dashboarddesigner", openKeys: ["dashboard"], selectedKey: "dashboard-designer" },
  { areaRelativeUrlSegment: "/queries", openKeys: ["queries"], selectedKey: "reporting-queries" },
  { areaRelativeUrlSegment: "/communications", openKeys: ["communications"], selectedKey: "reporting-communications" },
  { areaRelativeUrlSegment: "/history", openKeys: ["history"], selectedKey: "reporting-history" },
];

const appBuilderUrlToMenuMappings: UrlToMenuMapping[] = [
  { areaRelativeUrlSegment: "/templates", openKeys: ["appbuilder"], selectedKey: "templates-app" },
  { areaRelativeUrlSegment: "/applications", openKeys: ["appbuilder"], selectedKey: "appbuilder-myapps" },
  { areaRelativeUrlSegment: "/messages/texts", openKeys: ["messages"], selectedKey: "settings-messages" },
  { areaRelativeUrlSegment: "/support", openKeys: ["messages"], selectedKey: "settings-support" },
  { areaRelativeUrlSegment: "/updates", openKeys: ["messages"], selectedKey: "settings-otas" },
  { areaRelativeUrlSegment: "/disclaimer/texts", openKeys: ["messages"], selectedKey: "settings-disclaimer" },
  { areaRelativeUrlSegment: "/services/texts", openKeys: ["messages"], selectedKey: "settings-messages" },
  { areaRelativeUrlSegment: "/login/texts", openKeys: ["messages"], selectedKey: "settings-login" },
  { areaRelativeUrlSegment: "/registration/texts", openKeys: ["messages"], selectedKey: "settings-registration" },
  { areaRelativeUrlSegment: "/ageratings", openKeys: ["settings"], selectedKey: "settings-age-ratings" },
  { areaRelativeUrlSegment: "/translations", openKeys: ["settings"], selectedKey: "settings-translations" },
  { areaRelativeUrlSegment: "/api", openKeys: ["api"], selectedKey: "api-byoa" },
];

export const getOpenKeys = (url: string, area: string): string[] => {

  if (!url || !area) {
    return ["dashboard"];
  }

  let urlToMenuMappings: UrlToMenuMapping[] = [];

  if (area === "content") {
    urlToMenuMappings = contentUrlToMenuMappings;
  }
  else if (area === "inventory") {
    urlToMenuMappings = inventoryUrlToMenuMappings;
  }
  else if (area === "billing") {
    urlToMenuMappings = billingUrlToMenuMappings;
  }
  else if (area === "customers") {
    urlToMenuMappings = customersUrlToMenuMappings;
  }
  else if (area === "marketing") {
    urlToMenuMappings = marketingUrlToMenuMappings;
  }
  else if (area === "reporting") {
    urlToMenuMappings = reportingUrlToMenuMappings;
  }
  else if (area === "appbuilder") {
    urlToMenuMappings = appBuilderUrlToMenuMappings;
  }

  const foundMapping = urlToMenuMappings.find(mapping => url.substring(url.indexOf(`/${area}`) + `/${area}`.length).startsWith(mapping.areaRelativeUrlSegment));

  return foundMapping?.openKeys ?? ["dashboard"];

}

export const getSelectedKey = (url: string, area: string): string[] => {

  if (!url || !area) {
    return ["dashboard-dashboard"];
  }

  let urlToMenuMappings: UrlToMenuMapping[] = [];

  if (area === "content") {
    urlToMenuMappings = contentUrlToMenuMappings;
  }
  else if (area === "inventory") {
    urlToMenuMappings = inventoryUrlToMenuMappings;
  }
  else if (area === "billing") {
    urlToMenuMappings = billingUrlToMenuMappings;
  }
  else if (area === "customers") {
    urlToMenuMappings = customersUrlToMenuMappings;
  }
  else if (area === "marketing") {
    urlToMenuMappings = marketingUrlToMenuMappings;
  }
  else if (area === "reporting") {
    urlToMenuMappings = reportingUrlToMenuMappings;
  }
  else if (area === "appbuilder") {
    urlToMenuMappings = appBuilderUrlToMenuMappings;
  }

  const foundMapping = urlToMenuMappings.find(mapping => url.substring(url.indexOf(`/${area}`) + `/${area}`.length).startsWith(mapping.areaRelativeUrlSegment));

  return foundMapping ? [foundMapping.selectedKey] : ["dashboard-dashboard"];

}

