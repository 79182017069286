import axiosInstance from '../../../../utils/axios';
import { useQuery } from '@tanstack/react-query';
import type { IAppStoreProvider } from '@mwaretv/appstores';

export const useAppStoresProviderSchemas = () =>
  useQuery(
    ['appstoreSchemas'],
    async () => {
      const response = await axiosInstance.get<IAppStoreProvider[]>(`/v1/billing/app-stores-schemas`);
      return response.data;
    },
    {
      retry: true,
    }
  );
