import React from 'react'
import { Route, Switch, useRouteMatch } from 'react-router';
import AppStoresMethodsList from './list'
import MyAppStoresMethodsList from './myGatewaysList'

const Index = () => {
  const { path } = useRouteMatch();

  return (
    <Switch>
      <Route path={`${path}/my-gateways`}><MyAppStoresMethodsList /></Route>
      <Route path={`${path}`}><AppStoresMethodsList /></Route>
    </Switch>
  )
}

export default Index
