import axiosInstance from '../../../../utils/axios';
import { API_ENDPOINTS } from '../../../_APIConstants';
import { ReportDesign } from '@mwaretv/database/build/backend/models/reporting/reportDesigns/reportDesign';
import { TvmsApiResponseData } from '@mwaretv/database/build/backend/interfaces/tvmsApiResponseData';
import { TvmsApiRequestData } from '@mwaretv/database/build/backend/interfaces/tvmsApiRequestData';

class ReportDesignService {
  Add = async (requestData: TvmsApiRequestData<ReportDesign>): Promise<TvmsApiResponseData<ReportDesign>> => {

    return await axiosInstance
      .post<TvmsApiResponseData<ReportDesign>>(API_ENDPOINTS.V1.REPORTING.REPORT_DESIGNS.ADD, requestData.payload, {
        params: requestData.queryParams,
      })
      .then((response) => {
        return response.data;
      })
      .catch((err) => {
        return err;
      });
  };

  List = async (requestData: TvmsApiRequestData): Promise<TvmsApiResponseData<ReportDesign[]>> => {

    return await axiosInstance
      .post<TvmsApiResponseData<ReportDesign[]>>(API_ENDPOINTS.V1.REPORTING.REPORT_DESIGNS.LIST, requestData.payload, {
        params: requestData.queryParams,
      })
      .then((response) => {
        return response.data;
      })
      .catch((err) => {
        return err;
      });
  };

  Get = async (requestData: TvmsApiRequestData): Promise<TvmsApiResponseData<ReportDesign>> => {

    return await axiosInstance
      .get<TvmsApiResponseData<ReportDesign>>(`${API_ENDPOINTS.V1.REPORTING.REPORT_DESIGNS.DETAILS}/${requestData.urlParams?.id}`, {
        params: requestData.queryParams,
      })
      .then((response) => {
        return response.data;
      })
      .catch((err) => {
        return err;
      });
  };

  Update = async (requestData: TvmsApiRequestData): Promise<TvmsApiResponseData<ReportDesign>> => {

    return await axiosInstance
      .put<TvmsApiResponseData>(`${API_ENDPOINTS.V1.REPORTING.REPORT_DESIGNS.UPDATE}/${requestData.urlParams?.id}`, requestData.payload, {
        params: requestData.queryParams,
      })
      .then((response) => {
        return response.data;
      })
      .catch((err) => {
        return err;
      });
  };

  Delete = async (requestData: TvmsApiRequestData): Promise<TvmsApiResponseData<ReportDesign>> => {

    return await axiosInstance
      .delete<TvmsApiResponseData>(`${API_ENDPOINTS.V1.REPORTING.REPORT_DESIGNS.DETAILS}/${requestData.urlParams?.id}`, {
        params: requestData.queryParams,
      })
      .then((response) => {
        return response.data;
      })
      .catch((err) => {
        return err;
      });
  };
}
const REPORTDESIGN_SERVICE = new ReportDesignService();
export default REPORTDESIGN_SERVICE;
