import { Route, Switch, useRouteMatch } from 'react-router-dom';
import Dashboard from './dashboard';

const DashboardIndex = () => {
  const { path } = useRouteMatch();

  return (
    <Switch>
      <Route path={`${path}`}>
        <Dashboard showActionButtons={true} />
      </Route>
    </Switch>

  );
};

export default DashboardIndex;
