import { Form, notification } from 'antd';
import { useContext, useState } from 'react';
import { useHistory, useParams, useRouteMatch } from 'react-router';
import { BackButton } from '../../../../common/components/BackButton';
import { ContainerWrapper } from '../../../../common/components/containerWrapper';
import { Button } from '../../../../common/components/form';
import { PageTitle } from '../../../../common/components/pageTitle';
import { ErrorModal } from '../../../../common/components/ErrorModal/ErrorModal';
import { useTranslation } from 'react-i18next';
import * as HttpStatusCodes  from 'http-status-codes';
import reportDesignService from '../../../../services/areas/reporting/reportdesigns/reportdesignservice';
import { IMSNameParams } from '../../../dashboard';
import { useEffectOnce } from 'react-use';
import { ReportDesignFields } from './shared/reportdesignform';
import { TvmsApiRequestData } from '@mwaretv/database/build/backend/interfaces/tvmsApiRequestData';
import { ReportDesign } from '@mwaretv/database/build/backend/models/reporting/reportDesigns/reportDesign';
import { ReportingContext } from '../../../../contexts/reportingContext';
import { TvmsApiResponseData } from '@mwaretv/database/build/backend/interfaces/tvmsApiResponseData';
import { ChartDesign } from '@mwaretv/database/build/backend/models/reporting/chartdesigns/chartdesign';
import chartDesignService from '../../../../services/areas/reporting/chartdesigns/chartDesignService';
 
const DetailsReportDesign = () => {
  const { url } = useRouteMatch();
  const redirectUrl = url.split('/').slice(0, -2).join('/');
  const history = useHistory();
  const { clientName, _id } = useParams<IMSNameParams>();
  const { deployment, service } = useContext(ReportingContext);
  const {t} = useTranslation();
  const [openErrorModal, setOpenErrorModal] = useState(false);
  const [errorModalData, setErrorModalData] = useState([] as any);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [isLoading, setIsLoading] = useState(true);
  const [isError, setIsError] = useState(false);
  const [chartDesignResponseData, setChartDesignResponseData] = useState<TvmsApiResponseData<ChartDesign[]> | undefined>(undefined);
  const [form] = Form.useForm();

  const handleSubmit = async (reportDesign) => {
    setIsSubmitting(true);

    try {
      for (let i = 0; i < reportDesign.pages.length; i++) {
        reportDesign.pages[i].order = i;
      }
      
      const requestData: TvmsApiRequestData<ReportDesign> = {
        urlParams: {
          id: _id,
        },
        queryParams: {
          clientName: clientName,
          deploymentName: deployment?.name ?? "",
          serviceName: service?.name ?? "",
        },
        payload: {
          data: reportDesign,
        }
      };

      const chartDesignResponse = await reportDesignService.Update(requestData);
      if (chartDesignResponse.statusCode === HttpStatusCodes.OK) {
        notification.success({
          message: t('report-design-updated-successfully'),
        });
        history.push(redirectUrl);
      } else {
        notification.error({
          message: t('an-error-occured'),
        });
      }

    }
    catch (error) {
      console.log(error);
      notification.error({
        message: t('an-error-occured'),
      });
    }
    
    setIsSubmitting(false);
  };

  const getChartDesigns = async () => {
    setIsLoading(true);
    try {
      const requestData: TvmsApiRequestData = {
        queryParams: {
          clientName: clientName,
          deploymentName: deployment?.name ?? "",
          serviceName: service?.name ?? "",
        },
        payload: {
          pagination: {
            pageNumber: 1,
            pageSize: 9999,
          },
          sorting: {
            fieldName: "timestamp",
            descending: true,
          }
        }
      }

      const responseData = await chartDesignService.List(requestData);

      setChartDesignResponseData(responseData);
    } catch (error) {
      console.log(error);
      setIsError(true);
    }
    setIsLoading(false);
  };

  const getReportDesign = async () => {
    setIsLoading(true);

    try {
      const requestData: TvmsApiRequestData<ReportDesign> = {
        urlParams: {
          id: _id,
        },
        queryParams: {
          clientName: clientName,
          deploymentName: deployment?.name ?? "",
          serviceName: service?.name ?? "",
        },
      };

      const responseData = await reportDesignService.Get(requestData);
      if (responseData.statusCode === HttpStatusCodes.OK) {

        const values: any = responseData.data;

        form.setFieldsValue(values);
      } else {
        console.log(responseData);
        setIsError(true);
      }
    }
    catch (error) {
      console.log(error);
      setIsError(true);
    }
    setIsLoading(false);
  };

  useEffectOnce(() => {
    const fetchData = async () => {
      await getChartDesigns();
      await getReportDesign();
    };
    fetchData();
  });

  return (
    <ContainerWrapper>
      <div className="row">
        <div className="col-12">
          <BackButton text={t('chart-design-back-to-designer')} url={redirectUrl} />
        </div>
        <div className="col-md-6 mb-2">
          <PageTitle title={t('chart-design-update-report')} subtitle={t('chart-design-update-this-report')} />
        </div>
      </div>
      <ErrorModal
        isOpenModal={openErrorModal}
        data={errorModalData}
        setIsOpen={(isOpen) => {
          setOpenErrorModal(isOpen);
        }}
      />
      <div className="row">
        <div className="col-12">
          <Form
            {...{ form }}
            initialValues={{
              pages: [],
            }}
            onFinish={(values) => {
              handleSubmit(values);
            }}
            onFinishFailed={(errorInfo) => {
              setErrorModalData(errorInfo?.errorFields);
              setOpenErrorModal(true);
            }}
            layout="vertical"
          >
            {!isLoading && !isError && chartDesignResponseData?.data &&
            <>
              <ReportDesignFields availableCharts={chartDesignResponseData.data} />
              <div className="text-right">
                <Button htmlType="submit" loading={isSubmitting} disabled={isSubmitting} className="mt-4 ant-btn btn btn-secondary rounded-pill width-240 height-48">
                  {t('chart-design-update-report')}
                </Button>
              </div>
            </>
            }
          </Form>
        </div>
      </div>
    </ContainerWrapper>
  );
};

export default DetailsReportDesign;
