import { Form, notification } from 'antd';
import { useContext, useState } from 'react';
import { useHistory, useParams, useRouteMatch } from 'react-router';
import { BackButton } from '../../../../common/components/BackButton';
import { ContainerWrapper } from '../../../../common/components/containerWrapper';
import { Button } from '../../../../common/components/form';
import { PageTitle } from '../../../../common/components/pageTitle';
import { ErrorModal } from '../../../../common/components/ErrorModal/ErrorModal';
import { useTranslation } from 'react-i18next';
import * as HttpStatusCodes  from 'http-status-codes';
import chartDesignService from '../../../../services/areas/reporting/chartdesigns/chartDesignService';
import { ChartDesignFormFields } from './shared/chartdesignform';
import { IMSNameParams } from '../../../dashboard';
import { MapFormValuesToDomainValues } from './shared/mapper';
import { CommonContext } from '../../../../contexts/commonContext';
import { ChartDesignAvailability } from '@mwaretv/database/build/backend/models/reporting/chartdesigns/chartDesignConstants';
import dashboardService from '../../../../services/areas/reporting/dashboards/dashboardService';
import { DrawerContext } from '../../../../context_api/DrawerContext';
import React from 'react';
import { ClientType } from '@mwaretv/database/build/backend/models/base/clientTypeEnum';
import { TvmsApiRequestData } from '@mwaretv/database/build/backend/interfaces/tvmsApiRequestData';
import { ChartDesign } from '@mwaretv/database/build/backend/models/reporting/chartdesigns';

const AddChartDesign = () => {
  const { state: DrawerState } = React.useContext(DrawerContext);
  const { url } = useRouteMatch();
  const redirectUrl = url.split('/').slice(0, -1).join('/');
  const history = useHistory();
  const { clientName, deployment, service } = useParams<IMSNameParams>();
  const { countries } = useContext(CommonContext);
  const {t} = useTranslation();
  const [openErrorModal, setOpenErrorModal] = useState(false);
  const [errorModalData, setErrorModalData] = useState([] as any);
  const [isLoading, setIsLoading] = useState(false);

  const handleSubmit = async (chartDesign: ChartDesign) => {
    setIsLoading(true);

    try {
      const chartDesignRequestData: TvmsApiRequestData<ChartDesign> = {
        queryParams: {
          clientName: clientName,
          deploymentName: deployment ?? "",
          serviceName: service,
        },
        payload: {
          data: chartDesign,
        }
      };

      const chartDesignResponse = await chartDesignService.Add(chartDesignRequestData);

      if (chartDesignResponse.statusCode === HttpStatusCodes.OK) {

        const dashboardRequestData: TvmsApiRequestData = {
          urlParams: {
            area: "reporting",
          },
          queryParams: {
            clientName: clientName,
            deploymentName: deployment ?? "",
            serviceName: service,
          },
        };
    
        const dashboardResponse = await dashboardService.Generate(dashboardRequestData);
    
        if (dashboardResponse.statusCode !== HttpStatusCodes.OK) {
          console.log(JSON.stringify(dashboardResponse));
        }

        notification.success({
          message: t('added-successfully'),
        });
        history.push(redirectUrl);
      } else {
        console.log(chartDesignResponse);
        notification.error({
          message: t('an-error-occured'),
        });
      }
    }
    catch (error) {
      console.log(error);
      notification.error({
        message: t('an-error-occured'),
      });
    }

    setIsLoading(false);
  };

  const [form] = Form.useForm();

  return (
    <ContainerWrapper>
      <div className="row">
        <div className="col-12">
          <BackButton text={redirectUrl.indexOf('queries') > 0 ? t('chart-design-back-to-designer') : t('chart-design-back-to-dashboard')} url={redirectUrl} />
        </div>
        <div className="col-md-6 mb-2">
          <PageTitle title={t('chart-design-add-chart')} subtitle={t('chart-design-add-a-new-chart')} />
        </div>
      </div>
      <ErrorModal
        isOpenModal={openErrorModal}
        data={errorModalData}
        setIsOpen={(isOpen) => {
          setOpenErrorModal(isOpen);
        }}
      />
      <div className="row">
        <div className="col-12">
          <Form
            {...{ form }}
            initialValues={{
              dashboard: "reporting",
              series: [
                {
                  filters: [],
                }
              ]
            }}
            onFinish={(values) => {

              const domainChartDesign = MapFormValuesToDomainValues(values);

              handleSubmit(domainChartDesign);
            }}
            onFinishFailed={(errorInfo) => {
              setErrorModalData(errorInfo?.errorFields);
              setOpenErrorModal(true);
            }}
            layout="vertical"
          >
            {countries && DrawerState.client_info &&
            <>
              <ChartDesignFormFields availableChartDesignEntities={ChartDesignAvailability.getEntities(DrawerState.client_info.type === ClientType.PROFESSIONAL || DrawerState.client_info.type === ClientType.ENTERPRISE, countries)} />
              <div className="text-right">
                <Button htmlType="submit" loading={isLoading} disabled={isLoading} className="mt-4 ant-btn btn btn-secondary rounded-pill width-240 height-48">
                  {t('chart-design-add-chart')}
                </Button>
              </div>
            </>
            }
          </Form>
        </div>
      </div>
    </ContainerWrapper>
  );
};

export default AddChartDesign;
