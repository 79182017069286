import { faCode, faDashboard, faGears, faObjectsColumn, faTelevision, faText } from '@fortawesome/pro-regular-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Layout, Menu } from 'antd';
import React, { useState } from 'react';
import { Route, Switch, useRouteMatch } from 'react-router';
import { Link, useHistory, useParams } from 'react-router-dom';
import MWare_Logo from '../../../assets/images/logo.png';
import IMSDashboardHeader from '../../../common/components/IMSDashboardHeader';
import { AppBuilderContextProvider } from '../../../contexts/appbuilderContext';
import * as DeploymentService from '../../../services/deployments/deployment';
import { useQuery } from '@tanstack/react-query';
import Applications from './applications/index';
import MWare_Icon from '../../../assets/images/icon.png';
import AgeRatigns from './ageratings/index';
import Messages from './messages/index';
import Desclaimers from './disclaimer/index';
import Login from './login/index';
import Services from './services/index';
import Registration from './registration/index';
import Dashboard from './dashboard/index';
import Templates from './templates/index';
import Translations from './translations/index';
import { useEffectOnce, useLocation } from 'react-use';
import { hasRole } from '../../../utils/Auth/role.auth';
import { AuthContext } from '../../../context_api/AuthContext';
import { useContext } from 'react';
import Api from './api/v1';
import { useTranslation } from 'react-i18next';
import { getOpenKeys, getSelectedKey } from '../../../utils/menu';

const AppBuilder = () => {
  const location = useLocation();
  const { t } = useTranslation();
  const { path, url } = useRouteMatch();
  const { clientName, deployment, service } = useParams<{ clientName: string; deployment: string; service: string }>();
  const redirectUrl = url.split('/').slice(0, -1).join('/') + '/content';
  const [collapsed, setCollapsed] = useState(false);

  const { data, isLoading, isError } = useQuery(['deployment', deployment], async () => {
    return DeploymentService.find(clientName, { name: deployment })
      .then((r) => r.data)
      .catch((e) => Promise.reject(e));
  });

  const history = useHistory();
  const { state: AuthState } = useContext(AuthContext);
  const isClient = hasRole(AuthState.user!, clientName, ['superadmin', 'admin']);

  var locSplit = location?.pathname?.split('/');
  var redirectPath = '';
  if (locSplit != undefined) {
    redirectPath = locSplit.slice(0, 4 - locSplit.length).join('/');
  }
  useEffectOnce(() => {
    const str = window.location.href;
    const parts = str.split('/');
    const lastPart = parts[parts.length - 1];
    if (!isClient && lastPart == 'appbuilder') {
      history.push(url + '/applications');
    }
  });

  return (
    <Layout>
      <Layout.Sider width="300" collapsible collapsed={collapsed} onCollapse={(value) => {
          setCollapsed(value);
          window.dispatchEvent(new Event('resize'));
        }}
      >
        <div
          style={{
            justifyContent: 'center',
            alignContent: 'center',
            display: 'flex',
            padding: 40,
            paddingTop: 57,
            paddingBottom: 56,
            marginBottom: 20,
            borderBottom: '1px #444 solid',
            backgroundColor:'#002140'
          }}
        >
          {!isClient && (
            <Link to={'/'} onClick={() => {}} key="Mware">
              {!collapsed && <img className="expand" src={MWare_Logo} title={'MwareTV'} alt={'MwareTV'} style={{ height: 30 }} />}
              {collapsed && <img className="expand" src={MWare_Icon} title={'MwareTV'} alt={'MwareTV'} style={{ height: 30 }} />}
            </Link>
          )}
          {isClient && (
            <a href={redirectPath} key="Mware">
              {!collapsed && <img className="expand" src={MWare_Logo} title={'MwareTV'} alt={'MwareTV'} style={{ height: 30 }} />}
              {collapsed && <img className="expand" src={MWare_Icon} title={'MwareTV'} alt={'MwareTV'} style={{ height: 30 }} />}
            </a>
          )}
        </div>
        <Menu
          mode="inline"
          inlineCollapsed={collapsed}
          theme="dark"
          defaultSelectedKeys={getSelectedKey(location?.pathname ?? "", "appbuilder")}
          defaultOpenKeys={getOpenKeys(location?.pathname ?? "", "appbuilder")}
        items={[
            isClient
              ? {
                  icon: <FontAwesomeIcon icon={faDashboard} size="lg" style={{ width: '20', height: '20' }} />,
                  label: (
                    <div>
                      <span>{t('dashboard')}</span>
                    </div>
                  ),
                  key: 'dashboard',
                  children: [
                    {
                      label: (
                        <Link to={`${url}`}>
                          <span>{t('dashboard')}</span>
                        </Link>
                      ),
                      key: 'dashboard-dashboard',
                    },
                  ],
                }
              : {
                  label: <></>,
                  key: 'empty',
                },
            {
              icon: <FontAwesomeIcon icon={faObjectsColumn} size="lg" style={{ width: '20', height: '20' }} />,
              label: (
                <div>
                  <span>{t('app-builder')}</span>
                </div>
              ),
              key: 'appbuilder',
              children: [
                {
                  label: (
                    <Link to={`${url}/templates`}>
                      <span>{t('templates')}</span>
                    </Link>
                  ),
                  key: 'templates-app',
                },
                {
                  label: (
                    <Link to={`${url}/applications`}>
                      <span>{t('my-apps')}</span>
                    </Link>
                  ),
                  key: 'appbuilder-myapps',
                },
              ],
            },
            // {
            //   icon: <FontAwesomeIcon icon={faTelevision} size="lg" style={{ width: '20', height: '20' }} />,
            //   label: (
            //     <div>
            //       <span>{t('content')}</span>
            //     </div>
            //   ),
            //   key: 'content',
            //   children: [
            //     {
            //       label: (
            //         <Link to={`${redirectUrl}/channels/lists`}>
            //           <span>{t('channel-lists')}</span>
            //         </Link>
            //       ),
            //       key: 'templates-list1',
            //     },
            //     {
            //       label: (
            //         <Link to={`${redirectUrl}/radios/lists`}>
            //           <span>{t('radio-lists')}</span>
            //         </Link>
            //       ),
            //       key: 'templates-lsit2',
            //     },
            //     {
            //       label: (
            //         <Link to={`${redirectUrl}/cams/lists`}>
            //           <span>{t('cam-lists')}</span>
            //         </Link>
            //       ),
            //       key: 'templates-list3',
            //     },
            //     {
            //       label: (
            //         <Link to={`${redirectUrl}/movies/lists`}>
            //           <span>{t('movie-lists')}</span>
            //         </Link>
            //       ),
            //       key: 'templates-list4',
            //     },
            //     {
            //       label: (
            //         <Link to={`${redirectUrl}/series/lists`}>
            //           <span>{t('series-lists')}</span>
            //         </Link>
            //       ),
            //       key: 'templates-list5',
            //     },
            //     {
            //       label: (
            //         <Link to={`${redirectUrl}/shorts/lists`}>
            //           <span>{t('shorts-lists')}</span>
            //         </Link>
            //       ),
            //       key: 'templates-list55',
            //     },
            //     {
            //       label: (
            //         <Link to={`${redirectUrl}/courses/lists`}>
            //           <span>{t('courses-lists')}</span>
            //         </Link>
            //       ),
            //       key: 'templates-list6',
            //     },
            //     {
            //       label: (
            //         <Link to={`${redirectUrl}/albums/lists`}>
            //           <span>{t('album-lists')}</span>
            //         </Link>
            //       ),
            //       key: 'templates-list7',
            //     },
            //     {
            //       label: (
            //         <Link to={`${redirectUrl}/podcasts/lists`}>
            //           <span>{t('podcast-lists')}</span>
            //         </Link>
            //       ),
            //       key: 'templates-list8',
            //     },
            //     {
            //       label: (
            //         <Link to={`${redirectUrl}/games/lists`}>
            //           <span>{t('game-lists')}</span>
            //         </Link>
            //       ),
            //       key: 'templates-list88',
            //     },
            //   ],
            // },
            {
              icon: <FontAwesomeIcon icon={faText} size="lg" style={{ width: '20', height: '20' }} />,
              label: (
                <div>
                  <span>{t('app-texts')}</span>
                </div>
              ),
              key: 'messages',
              children: [
                {
                  label: (
                    <Link to={`${url}/messages/texts`}>
                      <span>{t('messages')}</span>
                    </Link>
                  ),
                  key: 'settings-messages',
                },
                {
                  label: (
                    <Link to={`${url}/support`}>
                      <span>{t('support')}</span>
                    </Link>
                  ),
                  key: 'settings-support',
                },
                {
                  label: (
                    <Link to={`${url}/updates`}>
                      <span>{t('updates')}</span>
                    </Link>
                  ),
                  key: 'settings-otas',
                },
                {
                  label: (
                    <Link to={`${url}/disclaimer/texts`}>
                      <span>{t('disclaimer')}</span>
                    </Link>
                  ),
                  key: 'settings-disclaimer',
                },
                {
                  label: (
                    <Link to={`${url}/services/texts`}>
                      <span>{t('services')}</span>
                    </Link>
                  ),
                  key: 'settings-servies',
                },
                {
                  label: (
                    <Link to={`${url}/login/texts`}>
                      <span>{t('login')}</span>
                    </Link>
                  ),
                  key: 'settings-login',
                },
                {
                  label: (
                    <Link to={`${url}/registration/texts`}>
                      <span>{t('registration')}</span>
                    </Link>
                  ),
                  key: 'settings-registration',
                },
              ],
            },
            {
              icon: <FontAwesomeIcon icon={faGears} size="lg" style={{ width: '20', height: '20' }} />,
              label: (
                <div>
                  <span>{t('settings')}</span>
                </div>
              ),
              key: 'settings',
              children: [
                {
                  label: (
                    <Link to={`${url}/ageratings`}>
                      <span>{t('age-ratings')}</span>
                    </Link>
                  ),
                  key: 'settings-age-ratings',
                },
                {
                  label: (
                    <Link to={`${url}/translations`}>
                      <span>{t('app-translations')}</span>
                    </Link>
                  ),
                  key: 'settings-translations',
                },
              ],
            },
            {
              icon: <FontAwesomeIcon icon={faCode} size="lg" style={{ width: '20', height: '20' }} />,
              label: (
                <div>
                  <span>{t('app-builder-api')}</span>
                </div>
              ),
              key: 'api',
              children: [
                {
                  label: (
                    <Link to={`${url}/api`}>
                      <span>{t('app-builder-api')}</span>
                    </Link>
                  ),
                  key: 'api-byoa',
                },
              ],
            },
          ]}
        />
      </Layout.Sider>
      <Layout>
        <div style={{ paddingLeft: 0, width: '100%' }}>
          <div>
            <IMSDashboardHeader
              {...{
                isSideBarShow: true,
                path,
              }}
            />
          </div>
          {/* {isLoading && <Spin />}  */}
          {isError && <span>{t('something-went-wrong')}</span>}
          {!isLoading && !isError && data && (
            <AppBuilderContextProvider value={{ deployment: data, service: data.services.find((x) => x.name === service) }}>
              <Switch>
                <Route path={`${path}/templates`}>
                  <Templates />
                </Route>
                <Route path={`${path}/translations`}>
                  <Translations />
                </Route>
                <Route path={`${path}/applications`}>
                  <Applications />
                </Route>
                <Route path={`${path}/ageratings`}>
                  <AgeRatigns />
                </Route>
                <Route path={`${path}/messages/texts`}>
                  <Messages />
                </Route>
                <Route path={`${path}/disclaimer/texts`}>
                  <Desclaimers />
                </Route>
                <Route path={`${path}/login/texts`}>
                  <Login />
                </Route>
                <Route path={`${path}/services/texts`}>
                  <Services />
                </Route>
                <Route path={`${path}/registration/texts`}>
                  <Registration />
                </Route>
                <Route path={`${path}/api`}>
                  <Api />
                </Route>
                <Route path={`${path}`}>{isClient && <Dashboard />}</Route>
              </Switch>
            </AppBuilderContextProvider>
          )}
        </div>
      </Layout>
    </Layout>
  );
};

export default AppBuilder;
