import { Route, Switch, useRouteMatch } from 'react-router-dom';
import AddChartDesign from '../chartdesigns/add';
import DetailsChartDesign from '../chartdesigns/details';
import ChartDesignList from './list';
import CopyChartDesign from '../chartdesigns/copy';

const DesignerIndex = () => {
  const { path } = useRouteMatch();

  return (
    <Switch>
      <Route path={`${path}/add`}>
        <AddChartDesign />
      </Route>
      <Route path={`${path}/details/:_id`}>
        <DetailsChartDesign />
      </Route>
      <Route path={`${path}/copy/:_id`}>
        <CopyChartDesign />
      </Route>
      <Route path={`${path}`}>
        <ChartDesignList />
      </Route>
    </Switch>

  );
};

export default DesignerIndex;
