import { Button, Card, Form, Select } from "antd";
import { useTranslation } from "react-i18next";
import { FormHeading, Input, Item } from "../../../../../common/components/form";
import { useState } from "react";
import { ArrowDownOutlined, ArrowUpOutlined, DeleteOutlined, PlusOutlined, QuestionOutlined } from "@ant-design/icons";
import { ChartDesign } from "@mwaretv/database/build/backend/models/reporting/chartdesigns/chartdesign";
import { AvailableChartWidths } from "@mwaretv/database/build/backend/models/reporting/chartdesigns/chartDesignConstants";

export const DashboardDesignFields = ({ availableCharts }: { availableCharts: ChartDesign[] }) => {
  const {t} = useTranslation();
  const [showHelp, setShowHelp] = useState(false);

  return (
    <>
      <Item hidden name={['_id']} />
      <Card>
        <div className="row">
          <div className="col-md-11">
            <FormHeading>{t('chart-design-dashboard-basics')}</FormHeading>
          </div>
          <div className="col-md-1">
            <FormHeading>
              <Button className="btn-primary rounded-pill" icon={<QuestionOutlined />} onClick={() => setShowHelp(!showHelp)} block />
            </FormHeading>
          </div>
        </div>
        <div className="row">
          <Item name={['name']} className="col-24-md-7" labelCol={{ span: 24 }} label={t('chart-design-dashboard-name')} rules={[{ required: true, message: t('chart-design-dashboard-name-is-required')}]}>
            <Input />
          </Item>
        </div>
      </Card>
      <Card>
        <div className="row">
          <div className="col-md-11">
            <FormHeading>{t('chart-design-dashboard-charts')}</FormHeading>
          </div>
          <div className="col-md-1">
            <FormHeading>
              <Button className="btn-primary rounded-pill" icon={<QuestionOutlined />} onClick={() => setShowHelp(!showHelp)} block />
            </FormHeading>
          </div>
        </div>
        <Form.List name={'chartInfos'}>
          {(chartInfos, { add, remove, move }) => (
            <>
            {(chartInfos?.length ?? 0) === 0 &&
              <div className="row">
                <div className="col-24-md-1">
                  <label>&nbsp;</label>
                  <Button style={{ textAlign: "center" }} type="default" size="large" icon={<PlusOutlined />} onClick={() => add()} block />
                </div>
              </div>
            }
            {(chartInfos?.length ?? 0) > 0 &&
              <>
              {chartInfos.map((chartInfo, index, array) => (
                <div className="row">
                  <div className="col-24-md-1">
                    <label>&nbsp;</label>
                    <Button style={{ textAlign: "center" }} type="default" size="large" icon={<ArrowUpOutlined />} onClick={() => move(index, index - 1)} block />
                  </div>
                  <div className="col-24-md-1">
                    <label>&nbsp;</label>
                    <Button style={{ textAlign: "center" }} type="default" size="large" icon={<ArrowDownOutlined />} onClick={() => move(index, index + 1)} block />
                  </div>
                  <Item name={[chartInfo.name, 'width12column']} className="col-24-md-7" labelCol={{ span: 24 }} label={t('chart-design-chart-width')} rules={[{ required: true, message: t('chart-design-chart-width-is-required') }]} extra={showHelp ? <div style={{ whiteSpace: "pre-wrap" }}>{t('chart-design-chart-width-extra')}</div> : undefined}>
                    <Select className="default-select" placeholder={t('chart-design-select-chart-width')}>
                      {AvailableChartWidths.map((chartType) => {
                        return (
                          <Select.Option value={chartType.value} key={chartType.value}>
                            {t(chartType.translationKey ?? "")}
                          </Select.Option>
                        );
                      })}
                    </Select>
                  </Item>
                  <Item name={[chartInfo.name, 'chartId']} className="col-24-md-7" labelCol={{ span: 24 }} label={t('chart-design-chart-name')} rules={[{ required: true, message: t('chart-design-chart-name-is-required') }]}>
                    <Select className="default-select" placeholder={t('chart-design-select-chart')}>
                      {availableCharts.map((chart) => {
                        return (
                          <Select.Option value={chart._id} key={chart._id}>
                            {chart.name}
                          </Select.Option>
                        );
                      })}
                    </Select>
                  </Item>
                  <div className="col-24-md-1">
                    <label>&nbsp;</label>
                    <Button style={{ textAlign: "center" }} type="default" size="large" icon={<DeleteOutlined />} onClick={() => remove(chartInfo.name)} block />
                  </div>
                  {index === (array.length - 1) &&
                    <div className="col-24-md-1">
                      <label>&nbsp;</label>
                      <Button style={{ textAlign: "center" }} type="default" size="large" icon={<PlusOutlined />} onClick={() => add()} block />
                    </div>
                  }
                </div>
              ))}
              </>
            }
            </>
          )}
        </Form.List>
      </Card>
    </>
  );
};