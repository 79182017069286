import React, { useContext, useState } from 'react';
import { useMutation, useQuery } from '@tanstack/react-query';
import { Link, useHistory, useParams, useRouteMatch } from 'react-router-dom';
import { IMSNameParams } from '../../../dashboard';
import { AppStores } from '../../../../interfaces/forms/areas/billing/appStores';
import { Button, Col, notification, Row, Tooltip } from 'antd';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {  faMemo, faBan, faCheck, faStore } from '@fortawesome/pro-regular-svg-icons';
import * as AppStoresService from '../../../../services/areas/billing/appStoresService';
import { ContainerWrapper } from '../../../../common/components/containerWrapper';
import { PageTitle } from '../../../../common/components/pageTitle';
import ReactBCTable from '../../../../common/components/ReactBCTable';
import { BillingContext } from '../../../../contexts/billingContext';
import { faUserCog } from '@fortawesome/pro-solid-svg-icons';
import { useTranslation } from 'react-i18next';

const AppstoresList: React.FC = () => {
  const { url } = useRouteMatch();
  const { clientName } = useParams<IMSNameParams>();
  const { deployment, service } = useContext(BillingContext);
  const history = useHistory();
  const [pagination, setPagination] = useState<{ limit: number; page: number }>({ limit: 50, page: 1 });
  const {t} = useTranslation();

  const query = useQuery(['shopping-carts', pagination.page, pagination.limit], async () => {
    return (
      await AppStoresService.list({
        params: {
          cms: deployment?.name || '',
          crm: service?.name || '',
          instance: clientName,
          page: pagination.page,
          limit: pagination.limit,
        },
      })
    ).data;
  });

  const { isLoading, isError, data } = query;

  const deleteMutation = useMutation(
    (id: string) => {
      return AppStoresService.del({ params: { instance: clientName, cms: deployment?.name || '', crm: service?.name || '' }, id });
    },
    {
      onSuccess: (data) => {
        notification.success({ message: t('shopping-cart-deleted') });
        query.refetch();
      },
      onError: () => {
        notification.error({ message: t('delete-failed') });
      },
    }
  );

  const disableMutation = useMutation(
    (id: string) => {
      return AppStoresService.disable({ params: { instance: clientName, cms: deployment?.name || '', crm: service?.name || '' }, id });
    },
    {
      onSuccess: (data) => {
        notification.success({ message: t('shopping-cart-disabled') });
        query.refetch();
      },
      onError: () => {
        notification.error({ message: t('disable-shopping-cart-failed') });
      },
    }
  );

  const enableMutation = useMutation(
    (id: string) => {
      return AppStoresService.disable({ params: { instance: clientName, cms: deployment?.name || '', crm: service?.name || '' }, id });
    },
    {
      onSuccess: (data) => {
        notification.success({ message: t('shopping-cart-enabled') });
        query.refetch();
      },
      onError: () => {
        notification.error({ message: t('enable-shopping-cart-failed') });
      },
    }
  );

  return (
    <ContainerWrapper>
      <div className="row">
        <div className="col-md-6 mb-2">
          <PageTitle title={t('carts-and-portals')} subtitle={t('carts-and-portals-are-used-to-let-your-customer-subscribe-and-manage-your-products-and-services')} />
        </div>
      </div>
      <div className="row">
        <div className="col-md-5 offset-md-7">
          <ActionBar />
        </div>
      </div>
      <div className="row">
        <div className="col-12 mt-4">
          {isError && <div>{t('something-went-wrong')}</div>}
          {!isError && data && (
            <ReactBCTable
              isLoading={isLoading}
              totalRecords={data.totalDocs}
              pageSize={data.limit}
              tableId={'billing_appstoress_table'}
              columns={[
                {
                  title: t('name'),
                  dataKey: 'name',
                  width: '75%',
                },
                {
                  title: t('id'),
                  dataKey: '_id',
                  width: '50%',
                },
                {
                  title: t('actions'),
                  dataKey: 'actions',

                  cell: (row: AppStores) => {
                    return (
                      <Col style={{ display: 'flex', flexDirection: 'row', height: 60 }}>
                        {/* {row._id !== undefined && (
                          <Tooltip placement="top" title={t('delete')}>
                            <Button
                              type="text"
                              disabled={deleteMutation.isLoading}
                              onClick={() => {
                                deleteMutation.mutate(row._id as string);
                              }}
                            >
                              <FontAwesomeIcon className="action-icon" icon={faTrashCan} />
                              <div>{t('delete')}</div>
                            </Button>
                          </Tooltip>
                        )} */}
                        <Tooltip placement="top" title={t('go-to-shop')}>
                          <Button type="text" onClick={() => window.open(`${process.env.REACT_APP_SHOP_URL_BASE}/?o=shop&h=${btoa(JSON.stringify({ client: clientName, deployment: deployment?.name, service: service?.name, portalId: row._id }))}`)} target="_blank">
                            <FontAwesomeIcon className="action-icon" icon={faStore} />
                            <div>{t('app-store')}</div>
                          </Button>
                        </Tooltip>
                        <Tooltip placement="top" title={t('go-to-portal')}>
                          <Button type="text" onClick={() => window.open(`${process.env.REACT_APP_CUSTOMER_PORTAL_BASE}/?o=home&h=${btoa(JSON.stringify({ client: clientName, deployment: deployment?.name, service: service?.name, portalId: row._id }))}`)} target="_blank">
                            <FontAwesomeIcon className="action-icon" icon={faUserCog} />
                            <div>{t('customer-portal')}</div>
                          </Button>
                        </Tooltip>
                        <Tooltip placement="top" title={t('details')}>
                          <Button
                            type="text"
                            disabled={deleteMutation.isLoading}
                            onClick={() => {
                              history.push(`${url}/${row._id}`);
                            }}
                          >
                            <FontAwesomeIcon className="action-icon" icon={faMemo} />
                            <div>{t('open-details')}</div>
                          </Button>
                        </Tooltip>
                        <Tooltip placement="top" title={row.is_disabled ? t('enable-shopping-cart') : t('disable-shopping-cart')}>
                          <Button
                            type="text"
                            onClick={() => {
                              row.is_disabled ? enableMutation.mutate(row._id as string) : disableMutation.mutate(row._id as string);
                            }}
                          >
                            <FontAwesomeIcon className="action-icon" icon={row.is_disabled ? faBan : faCheck} />
                            <div>{row.is_disabled ? t('disabled') : t ('enabled')}</div>
                          </Button>
                        </Tooltip>
                      </Col>
                    );
                  },
                },
              ]}
              data={data.docs || []}
              currentPage={data.page}
              onChange={(page) => {
                setPagination({ ...pagination, page });
              }}
            />
          )}
        </div>
      </div>
    </ContainerWrapper>
  );
};

const ActionBar: React.FC = () => {
  const { url } = useRouteMatch();
  const {t} = useTranslation();
  return (
    <div className="d-flex justify-content-center justify-content-md-end align-items-start">
      <Link className="btn btn-secondary rounded-pill btn-default float-right width-240 height-48" to={`${url}/add`}>
        {t('add-webshop')}
      </Link>
    </div>
  );
};

export default AppstoresList;
