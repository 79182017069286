import { Button, Table } from 'antd';
import * as HttpStatusCodes from 'http-status-codes';
import { useState } from 'react';
import { useParams } from 'react-router';
import actorsService from '../../../../../services/areas/content/actors/actors/index';
import { IMSNameParams } from '../../../../dashboard';
import { useEffectOnce } from 'react-use';
import { useTranslation } from 'react-i18next';
import { VisibleForRole } from '../../../../../common/components/VisibleForRole';
import { TVMSRoles } from '../../../../../constants/TVMSRoles';

const PpvRules = () => {
  const { clientName, deployment: service_name } = useParams<IMSNameParams & { deployment: string }>();
  const [isLoading, setIsloading] = useState(false);
  const [data, setData] = useState([] as any);
  const { t } = useTranslation();

  const importActors = async () => {
    setIsloading(true);
    await actorsService.Import_Actors({
      query_params: {
        instance: clientName,
        cms: service_name || '',
      },
    });
    setIsloading(false);
  };

  const getActors = async () => {
    setIsloading(true);
    let response = await actorsService.List_Actors({
      query_params: {
        instance: clientName,
        cms: service_name || '',
      },
      pageNumber: 1,
      pageSize: 10000,
    });
    if (response && response.status_code === HttpStatusCodes.OK) {
      if (response.data != undefined && response.data.data != undefined) {
        setData(response.data.data.sort((a, b) => a.name.localeCompare(b.name)));
      }
    }
    setIsloading(false);
  };
  useEffectOnce(() => {
    getActors();
  });

  const columns = [
    {
      title: t('image'),
      render: (row: any) => (
        <div style={{display:'flex', justifyContent:'center'}}>
          <img src={row.image} alt={''} height={100}  width={100} style={{ borderRadius: 100 }}></img>
        </div>
      ),
    },
    {
      title: t('name'),
      width: '90%',
      render: (row: any) => <div>{row.name}</div>,
    },
  ];

  return (
    <div className="container-fluid wrapper">
      <div className="row">
        <div className="col-md-7 mb-2">
          <h2 className="page-title">{t('actors')}</h2>
          <p className="h6 font-weight-normal line-height-26">{t('setup-your-content-actors-you-can-use-them-with-your-content-items-you-can-also-create-them-from-the-content-section-to')}</p>
        </div>
        <div className="col-md-5">
          <VisibleForRole roles={[TVMSRoles.SuperAdmin]}>
            <Button disabled={isLoading} loading={isLoading} className="btn btn-secondary rounded-pill btn-default float-right width-150 height-48" onClick={importActors}>
              {t('import-actors')}
            </Button>
          </VisibleForRole>
        </div>
      </div>
      <div className="row mt-4">
        <div className="col-md-7 mb-2"></div>
      </div>
      <div className="row">
        <div className="col-12 mt-4">
          <Table columns={columns} dataSource={data} loading={isLoading} />
        </div>
      </div>
    </div>
  );
};

export default PpvRules;
