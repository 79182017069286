import { Skeleton } from 'antd';
import { Application } from '../../../../../interfaces/forms/areas/appbuilder/applications/application/application';
import { Actors_Rails } from '../../../../../interfaces/forms/areas/appbuilder/builder/elements/rails/actors/actors';
import { GetRailsElements, getTitle } from './helpers/helper';
import { useTranslation } from 'react-i18next';

export type LayoutProps = {
  data: Actors_Rails;
  application: Application;
};

const ActorsRails = (props: LayoutProps) => {
  const { t } = useTranslation();

  return (
    <div style={{ backgroundColor: props.data.placement.background_color }}>
      <div
        className="container remove-hor-padding"
        style={{
          paddingTop: props.application.theme?.rails_top_margin ?? 5,
          paddingBottom: props.application.theme?.rails_bottom_margin ?? 5,
        }}
      >
        {props.data.content.list?.show_title != false && (
          <div className="text-left" style={{ marginLeft: 5 }}>
            {props.data.content.list?.title[0] && props.data.content.list?.title[0].title != 'Edit to add your text' && <>{getTitle(props.data)}</>}
            {(!props.data.content.list?.title[0] || props.data.content.list?.title[0].title == 'Edit to add your text') && <Skeleton.Input active={true} size={'large'} style={{ width: 200, height: 30, backgroundColor: '#bf8c8c' }} />}
          </div>
        )}
        
        <div style={{ gridAutoColumns: '100%', display: 'grid' }}>
          <div
            style={{
              display: 'grid',
              gridTemplateRows: 'min-content',
              gridAutoFlow: 'column',
              gridAutoColumns: 133,
              gap: 5,
              overflowX: 'auto',
              overscrollBehaviorInline: 'contain',
              marginTop: 10,
              marginLeft: 5,
              marginRight: 5,
            }}
          >
            <>
              <GetRailsElements form={'round'} isRound={true} type={props.data.content.list?.type ?? ''} props={props} color={'#999'} isActive={false} height={133} width={133}></GetRailsElements>
            </>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ActorsRails;
