import { Form, notification } from 'antd';
import { useContext, useState } from 'react';
import { useHistory, useParams, useRouteMatch } from 'react-router';
import { BackButton } from '../../../../common/components/BackButton';
import { ContainerWrapper } from '../../../../common/components/containerWrapper';
import { Button } from '../../../../common/components/form';
import { PageTitle } from '../../../../common/components/pageTitle';
import { ErrorModal } from '../../../../common/components/ErrorModal/ErrorModal';
import { useTranslation } from 'react-i18next';
import * as HttpStatusCodes  from 'http-status-codes';
import dashboardService from '../../../../services/areas/reporting/dashboards/dashboardService';
import { IMSNameParams } from '../../../dashboard';
import { DashboardDesign } from '@mwaretv/database/build/backend/models/reporting/dashboardDesigns/dashboardDesign';
import { ReportingContext } from '../../../../contexts/reportingContext';
import { TvmsApiRequestData } from '@mwaretv/database/build/backend/interfaces/tvmsApiRequestData';
import { DashboardDesignFields } from './shared/dashboarddesignform';
import chartDesignService from '../../../../services/areas/reporting/chartdesigns/chartDesignService';
import { TvmsApiResponseData } from '@mwaretv/database/build/backend/interfaces/tvmsApiResponseData';
import { ChartDesign } from '@mwaretv/database/build/backend/models/reporting/chartdesigns/chartdesign';
import { useEffectOnce } from 'react-use';

const AddDashboardDesign = () => {
  const { url } = useRouteMatch();
  const redirectUrl = url.split('/').slice(0, -1).join('/');
  const history = useHistory();
  const { clientName } = useParams<IMSNameParams>();
  const { deployment, service } = useContext(ReportingContext);
  const {t} = useTranslation();
  const [openErrorModal, setOpenErrorModal] = useState(false);
  const [errorModalData, setErrorModalData] = useState([] as any);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [isLoading, setIsLoading] = useState(true);
  const [isError, setIsError] = useState(false);
  const [chartDesignResponseData, setChartDesignResponseData] = useState<TvmsApiResponseData<ChartDesign[]> | undefined>(undefined);

  const handleSubmit = async (dashboardDesign) => {
    setIsSubmitting(true);

    try
    {
      for (let i = 0; i < dashboardDesign.chartInfos.length; i++) {
        dashboardDesign.chartInfos[i].order = i;
      }

      dashboardDesign.area = "reporting";

      const requestData: TvmsApiRequestData<DashboardDesign> = {
        queryParams: {
          clientName: clientName,
          deploymentName: deployment?.name ?? "",
          serviceName: service?.name ?? "",
        },
        payload: {
          data: dashboardDesign
        }
      };

      const dashboardDesignResponse = await dashboardService.Add(requestData);
      if (dashboardDesignResponse.statusCode === HttpStatusCodes.OK) {
        notification.success({
          message: t('added-successfully'),
        });
        history.push(redirectUrl);
      } else {
        console.log(dashboardDesignResponse);
        notification.error({
          message: t('an-error-occured'),
        });
      }
    }
    catch (error) {
      console.log(error);
      notification.error({
        message: t('an-error-occured'),
      });
    }

    setIsSubmitting(false);
  };

  const [form] = Form.useForm();

  const getChartDesigns = async () => {
    setIsLoading(true);
    try {
      const requestData: TvmsApiRequestData = {
        queryParams: {
          clientName: clientName,
          deploymentName: deployment?.name ?? "",
          serviceName: service?.name ?? "",
        },
        payload: {
          pagination: {
            pageNumber: 1,
            pageSize: 9999,
          },
          sorting: {
            fieldName: "timestamp",
            descending: true,
          }
        }
      }

      const responseData = await chartDesignService.List(requestData);
      if (responseData.statusCode === HttpStatusCodes.OK) {
        setChartDesignResponseData(responseData);
      } else {
        console.log(responseData);
        setIsError(true);
      }
    } catch (err) {
      console.log(err);
      setIsError(true);
    }
    setIsLoading(false);
  };

  useEffectOnce(() => {
    const fetchData = async () => {
      await getChartDesigns();
    };
    fetchData();
  });

  return (
    <ContainerWrapper>
      <div className="row">
        <div className="col-12">
          <BackButton text={t('chart-design-back-to-designer')} url={redirectUrl} />
        </div>
        <div className="col-md-6 mb-2">
          <PageTitle title={t('chart-design-add-dashboard')} subtitle={t('chart-design-add-a-new-dashboard')} />
        </div>
      </div>
      <ErrorModal
        isOpenModal={openErrorModal}
        data={errorModalData}
        setIsOpen={(isOpen) => {
          setOpenErrorModal(isOpen);
        }}
      />
      <div className="row">
        <div className="col-12">
          <Form
            {...{ form }}
            initialValues={{
              chartInfos: [],
            }}
            onFinish={(values) => {
              handleSubmit(values);
            }}
            onFinishFailed={(errorInfo) => {
              setErrorModalData(errorInfo?.errorFields);
              setOpenErrorModal(true);
            }}
            layout="vertical"
          >
            {!isLoading && !isError && chartDesignResponseData?.data &&
            <>
              <DashboardDesignFields availableCharts={chartDesignResponseData.data} />
              <div className="text-right">
                <Button htmlType="submit" loading={isSubmitting} disabled={isSubmitting} className="mt-4 ant-btn btn btn-secondary rounded-pill width-240 height-48">
                  {t('chart-design-add-dashboard')}
                </Button>
              </div>
            </>
            }
          </Form>
        </div>
      </div>
    </ContainerWrapper>
  );
};

export default AddDashboardDesign;
