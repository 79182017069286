import { SeasonsImport } from '../../../../../interfaces/forms/areas/content/series/seasons';
import { Series, SeriesExport, SeriesImport } from '../../../../../interfaces/forms/areas/content/series/series';
import { IGetForm } from '../../../../../interfaces/forms/common/ICMSExtraQuerytParams';
import { ICMS_Lists } from '../../../../../interfaces/forms/common/ICMS_Lists';
import { Seasons } from '../../../../../interfaces/forms/areas/content/series/seasons';
import { Series as Response } from '../../../../../interfaces/responses/areas/content/series/series';
import { IBaseResponse } from '../../../../../interfaces/responses/IBaseResponse';
import axiosInstance from '../../../../../utils/axios';
import { API_ENDPOINTS } from '../../../../_APIConstants';

class Series_Service {
  Srubber_Series = async (data: IGetForm): Promise<Response> => {
    return await axiosInstance
      .get(`${API_ENDPOINTS.V1.CMS.SERIES.SERIES.SCRUBBER}/${data._id}`, {
        params: {
          instance: data.query_params.instance,
          cms: data.query_params.cms,
          index: data.query_params.index,
        },
      })
      .then((response) => {
        return response.data;
      })
      .catch((err) => {
        return err;
      });
  };
  Add_Series = async (data: Series): Promise<IBaseResponse> => {
    return await axiosInstance
      .post(API_ENDPOINTS.V1.CMS.SERIES.SERIES.ADD, data, {
        params: {
          instance: data.query_params.instance,
          cms: data.query_params.cms,
        },
      })
      .then((response) => {
        return response.data;
      })
      .catch((err) => {
        return err;
      });
  };
  Import_Series = async (data: SeriesImport): Promise<IBaseResponse> => {
    return await axiosInstance
      .post(API_ENDPOINTS.V1.CMS.SERIES.SERIES.IMPORT, data, {
        params: {
          instance: data.query_params.instance,
          cms: data.query_params.cms,
          path: data.query_params.path,
          remove: data.query_params.remove,
          overwrite: data.query_params.overwrite,
          update: data.query_params.update,
          extend: data.query_params.extend,
          addcats: data.query_params.addcats,
          createcats: data.query_params.createcats,
          bundles: data.query_params.bundles,
          avoidDoubles: data.query_params.avoidDoubles,
        },
      })
      .then((response) => {
        return response.data;
      })
      .catch((err) => {
        return err;
      });
  };
  Import_Seasons = async (data: SeriesImport): Promise<IBaseResponse> => {
    return await axiosInstance
      .post(API_ENDPOINTS.V1.CMS.SERIES.SEASONS.IMPORT, data, {
        params: {
          instance: data.query_params.instance,
          cms: data.query_params.cms,
          path: data.query_params.path,
          remove: data.query_params.remove,
          overwrite: data.query_params.overwrite,
          update: data.query_params.update,
          extend: data.query_params.extend,
          avoidDoubles: data.query_params.avoidDoubles,
        },
      })
      .then((response) => {
        return response.data;
      })
      .catch((err) => {
        return err;
      });
  };
  Export_Series = async (data: SeriesExport): Promise<Response> => {
    return await axiosInstance
      .post(API_ENDPOINTS.V1.CMS.SERIES.SERIES.EXPORT, data, {
        params: {
          instance: data.query_params.instance,
          cms: data.query_params.cms,
        },
      })
      .then((response) => {
        return response.data;
      })
      .catch((err) => {
        return err;
      });
  };
  Export_Seasons = async (data: SeriesExport): Promise<Response> => {
    return await axiosInstance
      .post(API_ENDPOINTS.V1.CMS.SERIES.SEASONS.EXPORT, data, {
        params: {
          instance: data.query_params.instance,
          cms: data.query_params.cms,
        },
      })
      .then((response) => {
        return response.data;
      })
      .catch((err) => {
        return err;
      });
  };
  List_Seriess = async (data: ICMS_Lists): Promise<Response> => {
    return await axiosInstance
      .post(API_ENDPOINTS.V1.CMS.SERIES.SERIES.LIST, data, {
        params: data.query_params,
      })
      .then((response) => {
        return response.data;
      })
      .catch((err) => {
        return err;
      });
  };
  Details_Series = async (data: IGetForm): Promise<Response> => {
    return await axiosInstance
      .get(`${API_ENDPOINTS.V1.CMS.SERIES.SERIES.DETAILS}/${data._id}`, {
        params: {
          instance: data.query_params.instance,
          cms: data.query_params.cms,
        },
      })
      .then((response) => {
        return response.data;
      })
      .catch((err) => {
        return err;
      });
  };
  Update_Series = async (data: Series): Promise<IBaseResponse> => {
    return await axiosInstance
      .put(`${API_ENDPOINTS.V1.CMS.SERIES.SERIES.UPDATE}/${data._id}`, data, {
        params: {
          instance: data.query_params.instance,
          cms: data.query_params.cms,
        },
      })
      .then((response) => {
        return response.data;
      })
      .catch((err) => {
        return err;
      });
  };
  Delete_Series = async (data: IGetForm): Promise<IBaseResponse> => {
    return await axiosInstance
      .delete(`${API_ENDPOINTS.V1.CMS.SERIES.SERIES.DELETE}/${data._id}`, {
        params: {
          instance: data.query_params.instance,
          cms: data.query_params.cms,
          path: data.query_params.path,
        },
        data,
      })
      .then((response) => {
        return response.data;
      })
      .catch((err) => {
        return err;
      });
  };
  Delete_Seriess = async (data: IGetForm): Promise<IBaseResponse> => {
    return await axiosInstance
      .delete(API_ENDPOINTS.V1.CMS.SERIES.SERIES.DELETE_GROUP, {
        data,
        params: {
          instance: data.query_params.instance,
          cms: data.query_params.cms,
        },
      })
      .then((response) => {
        return response.data;
      })
      .catch((err) => {
        return err;
      });
  };

  Add_Season = async (data: Seasons): Promise<IBaseResponse> => {
    return await axiosInstance
      .post(API_ENDPOINTS.V1.CMS.SERIES.SEASONS.ADD, data, {
        params: {
          instance: data.query_params.instance,
          cms: data.query_params.cms,
        },
      })
      .then((response) => {
        return response.data;
      })
      .catch((err) => {
        return err;
      });
  };
  Add_Seasons = async (data: SeasonsImport): Promise<IBaseResponse> => {
    return await axiosInstance
      .post(API_ENDPOINTS.V1.CMS.SERIES.SEASONS.IMPORT, data, {
        params: {
          instance: data.query_params.instance,
          cms: data.query_params.cms,
        },
      })
      .then((response) => {
        return response.data;
      })
      .catch((err) => {
        return err;
      });
  };
  List_Seasons = async (data: ICMS_Lists): Promise<Response> => {
    return await axiosInstance
      .post(API_ENDPOINTS.V1.CMS.SERIES.SEASONS.LIST, data, {
        params: data.query_params,
      })
      .then((response) => {
        return response.data;
      })
      .catch((err) => {
        return err;
      });
  };
  Details_Season = async (data: IGetForm): Promise<Response> => {
    return await axiosInstance
      .get(`${API_ENDPOINTS.V1.CMS.SERIES.SEASONS.DETAILS}/${data._id}`, {
        params: {
          instance: data.query_params.instance,
          cms: data.query_params.cms,
        },
      })
      .then((response) => {
        return response.data;
      })
      .catch((err) => {
        return err;
      });
  };
  Delete_Seasons = async (data: IGetForm): Promise<IBaseResponse> => {
    return await axiosInstance
      .delete(API_ENDPOINTS.V1.CMS.SERIES.SEASONS.DELETE_GROUP, {
        data,
        params: {
          instance: data.query_params.instance,
          cms: data.query_params.cms,
        },
      })
      .then((response) => {
        return response.data;
      })
      .catch((err) => {
        return err;
      });
  };

  Update_Season = async (data: Series): Promise<IBaseResponse> => {
    return await axiosInstance
      .put(`${API_ENDPOINTS.V1.CMS.SERIES.SEASONS.UPDATE}/${data._id}`, data, {
        params: {
          instance: data.query_params.instance,
          cms: data.query_params.cms,
        },
      })
      .then((response) => {
        return response.data;
      })
      .catch((err) => {
        return err;
      });
  };
  Delete_Season = async (data: IGetForm): Promise<IBaseResponse> => {
    return await axiosInstance
      .delete(`${API_ENDPOINTS.V1.CMS.SERIES.SEASONS.DELETE}/${data._id}`, {
        params: {
          instance: data.query_params.instance,
          cms: data.query_params.cms,
        },
        data,
      })
      .then((response) => {
        return response.data;
      })
      .catch((err) => {
        return err;
      });
  };

  Add_Episode = async (data: any): Promise<IBaseResponse> => {
    return await axiosInstance
      .post(API_ENDPOINTS.V1.CMS.SERIES.EPISODES.IMPORT, data, {
        params: {
          instance: data.query_params.instance,
          cms: data.query_params.cms,
        },
      })
      .then((response) => {
        return response.data;
      })
      .catch((err) => {
        return err;
      });
  };
  List_Episodes = async (data: ICMS_Lists): Promise<Response> => {
    return await axiosInstance
      .post(API_ENDPOINTS.V1.CMS.SERIES.EPISODES.LIST, data, {
        params: data.query_params,
      })
      .then((response) => {
        return response.data;
      })
      .catch((err) => {
        return err;
      });
  };
  Details_Episode = async (data: IGetForm): Promise<Response> => {
    return await axiosInstance
      .get(`${API_ENDPOINTS.V1.CMS.SERIES.EPISODES.DETAILS}/${data._id}`, {
        params: {
          instance: data.query_params.instance,
          cms: data.query_params.cms,
        },
      })
      .then((response) => {
        return response.data;
      })
      .catch((err) => {
        return err;
      });
  };
  Update_Episode = async (data: Series): Promise<IBaseResponse> => {
    return await axiosInstance
      .put(`${API_ENDPOINTS.V1.CMS.SERIES.EPISODES.UPDATE}/${data._id}`, data, {
        params: {
          instance: data.query_params.instance,
          cms: data.query_params.cms,
        },
      })
      .then((response) => {
        return response.data;
      })
      .catch((err) => {
        return err;
      });
  };
  Delete_Episode = async (data: IGetForm): Promise<IBaseResponse> => {
    return await axiosInstance
      .delete(`${API_ENDPOINTS.V1.CMS.SERIES.EPISODES.DELETE}/${data._id}`, {
        params: {
          instance: data.query_params.instance,
          cms: data.query_params.cms,
        },
        data,
      })
      .then((response) => {
        return response.data;
      })
      .catch((err) => {
        return err;
      });
  };

  AutoComplete_List_Series = async (data: any): Promise<Response> => {
    return await axiosInstance
      .post(API_ENDPOINTS.V1.CMS.SERIES.SERIES.AUTOCOMPLETE_LIST, data, {
        params: data.query_params,
      })
      .then((response) => {
        return response.data;
      })
      .catch((err) => {
        return err;
      });
  };
}

export default new Series_Service();
