import { Shorts, ShortsExport, ShortsImport } from '../../../../../interfaces/forms/areas/content/shorts/shorts';
import { IGetForm } from '../../../../../interfaces/forms/common/ICMSExtraQuerytParams';
import { ICMS_Lists } from '../../../../../interfaces/forms/common/ICMS_Lists';
import { Shorts as Response } from '../../../../../interfaces/responses/areas/content/shorts/shorts';

import { IBaseResponse } from '../../../../../interfaces/responses/IBaseResponse';
import axiosInstance from '../../../../../utils/axios';
import { API_ENDPOINTS } from '../../../../_APIConstants';

class Shorts_Service {
  Srubber_Shorts = async (data: IGetForm): Promise<Response> => {
    return await axiosInstance
      .get(`${API_ENDPOINTS.V1.CMS.SHORTS.SHORTS.SCRUBBER}/${data._id}`, {
        params: {
          instance: data.query_params.instance,
          cms: data.query_params.cms,
        },
      })
      .then((response) => {
        return response.data;
      })
      .catch((err) => {
        return err;
      });
  };
  Add_Shorts = async (data: Shorts): Promise<Response> => {
    return await axiosInstance
      .post(API_ENDPOINTS.V1.CMS.SHORTS.SHORTS.ADD, data, {
        params: {
          instance: data.query_params.instance,
          cms: data.query_params.cms,
        },
      })
      .then((response) => {
        return response.data;
      })
      .catch((err) => {
        return err;
      });
  };
  Import_Shorts = async (data: ShortsImport): Promise<Response> => {
    return await axiosInstance
      .post(API_ENDPOINTS.V1.CMS.SHORTS.SHORTS.IMPORT, data, {
        params: {
          instance: data.query_params.instance,
          cms: data.query_params.cms,
          path: data.query_params.path,
          remove: data.query_params.remove,
          overwrite: data.query_params.overwrite,
          update: data.query_params.update,
          extend: data.query_params.extend,
          addcats: data.query_params.addcats,
          createcats: data.query_params.createcats,
          bundles: data.query_params.bundles,
          avoidDoubles: data.query_params.avoidDoubles,
        },
      })
      .then((response) => {
        return response.data;
      })
      .catch((err) => {
        return err;
      });
  };
  Export_Shorts = async (data: ShortsExport): Promise<Response> => {
    return await axiosInstance
      .post(API_ENDPOINTS.V1.CMS.SHORTS.SHORTS.EXPORT, data, {
        params: {
          instance: data.query_params.instance,
          cms: data.query_params.cms,
        },
      })
      .then((response) => {
        return response.data;
      })
      .catch((err) => {
        return err;
      });
  };
  List_Shorts = async (data: ICMS_Lists): Promise<Response> => {
    return await axiosInstance
      .post(API_ENDPOINTS.V1.CMS.SHORTS.SHORTS.LIST, data, {
        params: data.query_params,
      })
      .then((response) => {
        return response.data;
      })
      .catch((err) => {
        return err;
      });
  };
  Details_Shorts = async (data: IGetForm): Promise<Response> => {
    return await axiosInstance
      .get(`${API_ENDPOINTS.V1.CMS.SHORTS.SHORTS.DETAILS}/${data._id}`, {
        params: {
          instance: data.query_params.instance,
          cms: data.query_params.cms,
        },
      })
      .then((response) => {
        return response.data;
      })
      .catch((err) => {
        return err;
      });
  };
  Update_Shorts = async (data: Shorts): Promise<IBaseResponse> => {
    return await axiosInstance
      .put(`${API_ENDPOINTS.V1.CMS.SHORTS.SHORTS.UPDATE}/${data._id}`, data, {
        params: {
          instance: data.query_params.instance,
          cms: data.query_params.cms,
        },
      })
      .then((response) => {
        return response.data;
      })
      .catch((err) => {
        return err;
      });
  };

  Delete_Shorts = async (data: IGetForm): Promise<IBaseResponse> => {
    return await axiosInstance
      .delete(`${API_ENDPOINTS.V1.CMS.SHORTS.SHORTS.DELETE}/${data._id}`, {
        params: {
          instance: data.query_params.instance,
          cms: data.query_params.cms,
          path: data.query_params.path,
        },
        data,
      })
      .then((response) => {
        return response.data;
      })
      .catch((err) => {
        return err;
      });
  };
  // Delete_Shorts = async (data: IGetForm): Promise<IBaseResponse> => {
  //   return await axiosInstance
  //     .delete(API_ENDPOINTS.V1.CMS.SHORTS.SHORTS.DELETE_GROUP, {
  //       data,
  //       params: {
  //         instance: data.query_params.instance,
  //         cms: data.query_params.cms,
  //       },
  //     })
  //     .then((response) => {
  //       return response.data;
  //     })
  //     .catch((err) => {
  //       return err;
  //     });
  // };

  AutoComplete_List_Shorts = async (data: any): Promise<Response> => {
    return await axiosInstance
      .post(API_ENDPOINTS.V1.CMS.SHORTS.SHORTS.AUTOCOMPLETE_LIST, data, {
        params: data.query_params,
      })
      .then((response) => {
        return response.data;
      })
      .catch((err) => {
        return err;
      });
  };
}

export default new Shorts_Service();
