import { Alert, Layout, Menu } from 'antd';
import React, { useEffect, useState } from 'react';
import { Route, Switch, useParams, useRouteMatch } from 'react-router';
import { Link, useHistory } from 'react-router-dom';
import IMSDashboardHeader from '../../../common/components/IMSDashboardHeader';
import { DrawerContext } from '../../../context_api/DrawerContext';
import MWare_Logo from '../../../assets/images/logo.png';
import * as DeploymentService from '../../../services/deployments/deployment';
import { useQuery } from '@tanstack/react-query';
import { CustomersContextProvider } from '../../../contexts/customersContext';
import { faSquareUser, faHeadset, faDashboard } from '@fortawesome/pro-regular-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import Customers from './customers';
import Tickets from './tickets';
import Dashboard from './dashboard';
import MWare_Icon from '../../../assets/images/icon.png';
import Actions from './actions';
import { useEffectOnce, useLocation } from 'react-use';
import { AuthContext } from '../../../context_api/AuthContext';
import { useContext } from 'react';
import { hasRole } from '../../../utils/Auth/role.auth';
import { NotVisibleForRole, VisibleForRole } from '../../../common/components/VisibleForRole';
import { TVMSRoles } from '../../../constants/TVMSRoles';
import { useTranslation } from 'react-i18next';
import * as ProductService from '../../../services/areas/billing/productService';
import * as ShoppingCartService from '../../../services/areas/billing/shoppingCartService';
import * as PaymentProviderService from '../../../services/areas/billing/paymentProviderService';
import * as EmailGatewayService from '../../../services/areas/billing/emailGatewayService';
import * as SmsGatewayService from '../../../services/areas/billing/smsGatewayService';
import * as TaxService from '../../../services/areas/billing/taxService';
import axios from 'axios';
import { getOpenKeys, getSelectedKey } from '../../../utils/menu';

const Customer = () => {
  const location = useLocation();
  const { state: DrawerState } = React.useContext(DrawerContext);
  const { path, url } = useRouteMatch();
  const inventoryUrl = url.split('/').slice(0, -1).concat('inventory').join('/');
  const billingUrl = url.split('/').slice(0, -1).concat('billing').join('/');
  const { clientName, deployment, service } = useParams<{ clientName: string; deployment: string; service: string }>();
  const { state: AuthState } = useContext(AuthContext);
  const isClient = hasRole(AuthState.user!, clientName, ['superadmin', 'admin']);
  var locSplit = location?.pathname?.split('/');
  const history = useHistory();
  const [languagesInstalled, setLangaugesInstalled] = useState(true);
  const { t } = useTranslation();
  const [collapsed, setCollapsed] = useState(false);

  var redirectPath = '';
  if (locSplit != undefined) {
    redirectPath = locSplit.slice(0, 4 - locSplit.length).join('/');
  }

  useEffectOnce(() => {
    const str = window.location.href;
    const parts = str.split('/');
    const lastPart = parts[parts.length - 1];
    if (!isClient && lastPart == 'customers') {
      history.push(url + '/customers/list');
    }
  });

  const { data, isLoading, isError } = useQuery(['deployment', deployment], async () => {
    return DeploymentService.find(clientName, { name: deployment })
      .then((r) => r.data)
      .catch((e) => Promise.reject(e));
  });

  const taxes = useQuery(
    ['tax', 1, 100],
    async () => {
      return (
        await TaxService.list({
          params: {
            cms: deployment ?? '',
            crm: service || '',
            instance: clientName,
            page: 1,
            limit: 10,
          },
        })
      ).data;
    },
    { retry: 3 }
  );
  const products = useQuery(
    ['products', 1, 100],
    async () => {
      return (
        await ProductService.list({
          params: {
            cms: deployment ?? '',
            crm: service || '',
            instance: clientName,
            page: 1,
            limit: 10,
          },
        })
      ).data;
    },
    { retry: 3 }
  );
  const paymentgateways = useQuery(
    ['payment-providers', 1, 100],
    async () => {
      return (
        await PaymentProviderService.list({
          params: {
            cms: deployment ?? '',
            crm: service || '',
            instance: clientName,
            page: 1,
            limit: 10,
          },
        })
      ).data;
    },
    { retry: 3 }
  );
  const emailgateways = useQuery(
    ['email-gateway', 1, 100],
    async () => {
      return (
        await EmailGatewayService.list({
          params: {
            cms: deployment ?? '',
            crm: service || '',
            instance: clientName,
            page: 1,
            limit: 10,
          },
        })
      ).data;
    },
    { retry: 3 }
  );
  const smsgateways = useQuery(
    ['sms-gateway', 1, 100],
    async () => {
      return (
        await SmsGatewayService.list({
          params: {
            cms: deployment ?? '',
            crm: service || '',
            instance: clientName,
            page: 1,
            limit: 10,
          },
        })
      ).data;
    },
    { retry: 3 }
  );
  const shoppingCarts = useQuery(
    ['shoppingCarts', 1, 100],
    async () => {
      return (
        await ShoppingCartService.list({
          params: {
            cms: deployment ?? '',
            crm: service || '',
            instance: clientName,
            page: 1,
            limit: 10,
          },
        })
      ).data;
    },
    { retry: 3 }
  );
  const getLanguages = async (langs) => {
    var languagesOut = [] as any;
    try {
      const fetchCarouselPromises = langs.map(async (language) => await axios.get('https://cloudtv.akamaized.net/' + clientName + '/' + deployment + '/' + service + '/carts/translations/' + language.name + '.json'));
      Promise.allSettled(fetchCarouselPromises).then((values) => {
        values.forEach((value, index) => {
          if (value.status === 'rejected') {
            languagesOut.push({
              available: false,
              name: langs[index].name,
            });
          } else {
            languagesOut.push({
              available: true,
              name: langs[index].name,
            });
          }
        });
        var test = languagesOut.find((f) => f.available == false);
        if (test != undefined) {
          setLangaugesInstalled(false);
        }
      });
    } catch (err) {
    } finally {
    }
  };
  useEffect(() => {
    if (data != undefined) {
      getLanguages(data.services[0].languages);
    }
  }, [data]);

  return (
    <Layout>
      <NotVisibleForRole roles={[TVMSRoles.Vendor]}>
        <Layout.Sider
          width="300"
          collapsible
          collapsed={collapsed}
          onCollapse={(value) => {
            setCollapsed(value);
            window.dispatchEvent(new Event('resize'));
          }}
        >
          <div
            style={{
              justifyContent: 'center',
              alignContent: 'center',
              display: 'flex',
              padding: 40,
              paddingTop: 57,
              paddingBottom: 56,
              marginBottom: 20,
              borderBottom: '1px #444 solid',
              backgroundColor: '#002140',
            }}
          >
            {!isClient && (
              <Link to={'/'} onClick={() => {}} key="Mware">
                {!collapsed && <img className="expand" src={MWare_Logo} title={'MwareTV'} alt={'MwareTV'} style={{ height: 30 }} />}
                {collapsed && <img className="expand" src={MWare_Icon} title={'MwareTV'} alt={'MwareTV'} style={{ height: 30 }} />}
              </Link>
            )}
            {isClient && (
              <a href={redirectPath} key="Mware">
                {!collapsed && <img className="expand" src={MWare_Logo} title={'MwareTV'} alt={'MwareTV'} style={{ height: 30 }} />}
                {collapsed && <img className="expand" src={MWare_Icon} title={'MwareTV'} alt={'MwareTV'} style={{ height: 30 }} />}
              </a>
            )}
          </div>
          <Menu
            mode="inline"
            inlineCollapsed={collapsed}
            theme="dark"
            defaultSelectedKeys={getSelectedKey(location?.pathname ?? '', 'customers')}
            defaultOpenKeys={getOpenKeys(location?.pathname ?? '', 'customers')}
            items={[
              isClient
                ? {
                    icon: <FontAwesomeIcon icon={faDashboard} size="lg" style={{ width: '20', height: '20' }} />,
                    label: (
                      <div>
                        <span>{t('dashboard')}</span>
                      </div>
                    ),
                    key: 'dashboard',
                    children: [
                      {
                        label: (
                          <Link className="height-60 d-flex align-items-center ml-3" to={`${url}`}>
                            <span className="ml-2">{t('dashboard')}</span>
                          </Link>
                        ),
                        key: 'dashboard-dashboard',
                      },
                    ],
                  }
                : {
                    label: <></>,
                    key: 'empty',
                  },
              {
                icon: <FontAwesomeIcon icon={faSquareUser} size="lg" style={{ width: '20', height: '20' }} />,
                label: (
                  <div>
                    <span>{t('customers')}</span>
                  </div>
                ),
                key: 'customers',
                children: [
                  {
                    label: (
                      <Link className="height-60 d-flex align-items-center ml-3" to={`${url}/customers/list`}>
                        <span className="ml-2">{t('customers')}</span>
                      </Link>
                    ),
                    key: 'customers-customers',
                  },
                  {
                    label: (
                      <Link className="height-60 d-flex align-items-center ml-3" to={`${url}/actions/importer-excel`}>
                        <span className="ml-2">{t('import')}</span>
                      </Link>
                    ),
                    key: 'customers-import',
                  },
                ],
              },
              {
                icon: <FontAwesomeIcon icon={faHeadset} size="lg" style={{ width: '20', height: '20' }} />,
                label: (
                  <div>
                    <span>{t('tickets')}</span>
                  </div>
                ),
                key: 'tickets',
                children: [
                  {
                    label: (
                      <Link className="height-60 d-flex align-items-center ml-3" to={`${url}/tickets`}>
                        <span className="ml-2">{t('tickets')}</span>
                      </Link>
                    ),
                    key: 'ticketspage',
                  },
                ],
              },
              // {
              //   label: (
              //     <div >
              //       <FontAwesomeIcon icon={faDiagramProject} size="lg" style={{ width: '20', height: '20' }} />
              //       <span>Resellers</span>
              //     </div>
              //   ),
              //   key: 'resellers',
              //   children: [
              //     {
              //       label: (
              //         <Link className="height-60 d-flex align-items-center ml-3" to={`${url}/resellers`}>
              //           <span>Resellers</span>
              //         </Link>
              //       ),
              //       key: 'resellers',
              //     },
              //   ],
              // },

              // {
              //   label: (<div className='height-60 d-flex align-items-center'><FontAwesomeIcon icon={faGears} size='lg' /><span className='ml-3'>Settings</span></div>),
              //   key: 'settings',
              //   children: [
              //     // { label: (<Link className='height-60 d-flex align-items-center' to={`${url}/settings/general`}><span className='ml-3'>General</span></Link>), key: 'settings-general' },
              //     // { label: (<Link className='height-60 d-flex align-items-center' to={`${url}/settings/currencies`}><span className='ml-3'>Currencies</span></Link>), key: 'settings-currencies' },
              //     { label: (<Link className='height-60 d-flex align-items-center' to={`${url}/settings/company-info`}><span className='ml-3'>Company Info</span></Link>), key: 'settings-company-info' },
              //     // { label: (<Link className='height-60 d-flex align-items-center' to={`${url}/settings/log-translations`}><span className='ml-3'>Log Translations</span></Link>), key: 'settings-log-translations' },
              //     // { label: (<Link className='height-60 d-flex align-items-center' to={`${url}/settings/crm-rules`}><span className='ml-3'>CRM Rules</span></Link>), key: 'settings-crm-rules' },
              //     // { label: (<Link className='height-60 d-flex align-items-center' to={`${url}/settings/crm-rules`}><span className='ml-3'>CRM Rules</span></Link>), key: 'settings-crm-rules' },

              //   ]
              // },
            ]}
          />
        </Layout.Sider>
      </NotVisibleForRole>

      <NotVisibleForRole roles={[TVMSRoles.Vendor]}>
        <Layout>
          <div style={{ paddingLeft: 0, width: '100%' }}>
            <div>
              <IMSDashboardHeader
                {...{
                  isSideBarShow: true,
                  path,
                }}
              />
            </div>
            <div className="mt-3 ml-5 mr-5">
              {taxes.data?.docs.length == 0 && (
                <Alert
                  className="mt-4"
                  message={t('taxes')}
                  showIcon
                  description={t('you-have-not-yet-setup-any-tax-rules-you-need-the-tax-rules-before-you-can-create-packages')}
                  type="error"
                  action={
                    <Link className="btn btn-info rounded-pill float-right width-240 height-48" to={`${inventoryUrl}/settings/taxes`}>
                      {t('setup-taxes')}
                    </Link>
                  }
                />
              )}
              {products.data?.docs.length == 0 && (
                <Alert
                  className="mt-4"
                  message={t('products')}
                  showIcon
                  description={t('you-have-not-yet-defined-any-products-you-need-products-before-you-can-create-a-shopping-cart')}
                  type="error"
                  action={
                    <Link className="btn btn-info rounded-pill float-right width-240 height-48" to={`${inventoryUrl}/packages`}>
                      {t('create-product')}
                    </Link>
                  }
                />
              )}
              {paymentgateways.data?.docs.length == 0 && (
                <Alert
                  className="mt-4"
                  message={t('payment-gateway')}
                  showIcon
                  description={t('you-have-not-yet-defined-any-payment-gateways-you-need-a-payment-gateway-before-you-can-create-a-shopping-cart')}
                  type="info"
                  action={
                    <Link className="btn btn-info rounded-pill float-right width-240 height-48" to={`${billingUrl}/payment-gateways`}>
                      {t('create-payment-gateway')}
                    </Link>
                  }
                />
              )}
              {emailgateways.data?.docs.length == 0 && smsgateways.data?.docs.length == 0 && (
                <Alert
                  className="mt-4"
                  message={t('email-or-sms-gateway')}
                  showIcon
                  description={t('you-have-not-yet-defined-any-communication-gateways-you-need-either-an-email-or-sms-gateway-before-you-can-create-a-shopping-cart')}
                  type="info"
                  action={
                    <div className="col">
                      <div className="row mb-2">
                        <Link className="btn btn-info rounded-pill float-right width-240 height-48" to={`${billingUrl}/email-gateways`}>
                          {t('create-email-gateway')}
                        </Link>
                      </div>
                      <div className="row">
                        <Link className="btn btn-info rounded-pill float-right width-240 height-48" to={`${billingUrl}/sms-gateways`}>
                          {t('create-sms-gateway')}
                        </Link>
                      </div>
                    </div>
                  }
                />
              )}
              {shoppingCarts.data?.docs.length == 0 && (
                <Alert
                  className="mt-4"
                  message={t('shopping-carts')}
                  showIcon
                  description={t('you-have-not-yet-defined-any-shopping-carts-you-need-shopping-carts-before-you-can-add-customers')}
                  type="error"
                  action={
                    <Link className="btn btn-info rounded-pill float-right width-240 height-48" to={`${billingUrl}/shopping-carts`}>
                      {t('create-shopping-cart')}
                    </Link>
                  }
                />
              )}
              {!languagesInstalled && (
                <Alert
                  className="mt-4"
                  message={t('cart-and-portal-translations')}
                  showIcon
                  description={t('you-have-not-yet-installed-the-cart-and-portal-translations-this-is-a-mandatory-element')}
                  type="info"
                  action={
                    <Link className="btn btn-info rounded-pill float-right width-240 height-48" to={`${billingUrl}/settings/translations`}>
                      {t('install-translations')}
                    </Link>
                  }
                />
              )}
            </div>

            {/* {isLoading && <Spin />} */}
            {isError && <span>{t('something-went-wrong')}</span>}
            {!isLoading && !isError && data && (
              <CustomersContextProvider value={{ deployment: data, service: data.services.find((x) => x.name === service) }}>
                <Switch>
                  <Route path={`${path}/customers`}>
                    <Customers />
                  </Route>
                  <Route path={`${path}/tickets`}>
                    <Tickets />
                  </Route>
                  <Route path={`${path}/resellers`}>
                    <Customers />
                  </Route>
                  <Route path={`${path}/actions`}>
                    <Actions />
                  </Route>
                  <Route path={`${path}/`}>{isClient && <Dashboard />}</Route>
                </Switch>
              </CustomersContextProvider>
            )}
          </div>
        </Layout>
      </NotVisibleForRole>

      <VisibleForRole roles={[TVMSRoles.Vendor]}>
        <Layout>
          <div style={{ paddingLeft: 0, width: '100%' }}>
            <div>
              <IMSDashboardHeader
                {...{
                  isSideBarShow: true,
                  path,
                }}
              />
            </div>
            <div>
              {/* {isLoading && <Spin />} */}
              {isError && <span>{t('something-went-wrong')}</span>}
              {!isLoading && !isError && data && (
                <CustomersContextProvider value={{ deployment: data, service: data.services.find((x) => x.name === service) }}>
                  <Switch>
                    <Route path={`${path}/customers`}>
                      <Customers />
                    </Route>
                    <Route path={`${path}/tickets`}>
                      <Tickets />
                    </Route>
                    <Route path={`${path}/resellers`}>
                      <Customers />
                    </Route>
                    <Route path={`${path}/actions`}>
                      <Actions />
                    </Route>
                    <Route path={`${path}/`}>{isClient && <Dashboard />}</Route>
                  </Switch>
                </CustomersContextProvider>
              )}
            </div>
          </div>
        </Layout>
      </VisibleForRole>
    </Layout>
  );
};

export default Customer;
