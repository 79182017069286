import { Button, Form, notification } from 'antd';
import { useForm } from 'antd/lib/form/Form';
import { useTranslation } from 'react-i18next';
import * as HttpStatusCodes  from 'http-status-codes';
import { useContext, useState } from 'react';
import { useParams } from 'react-router-dom';
import ImageUploader from '../../../../../../../common/components/ImageUploader/ImageUploader';
import cmsService from '../../../../../../../services/areas/content/series/series';
import { IMSNameParams } from '../../../../../../dashboard';
import { ContentContext } from '../../../../../../../contexts/contentContext';
import { Courses } from '../../../../../../../interfaces/forms/areas/content/courses/courses';
import { useEffectOnce } from 'react-use';
import { STORAGE_ENDPOINTS } from '../../../../../../../services/storage_paths';

const AddNewMusicAlbum = (data: any) => {
  const { clientName, _id } = useParams<IMSNameParams>();
  const { service, deployment } = useContext(ContentContext);
  const [isAdding, setIsAdding] = useState(false);
  const [poster, setPoster] = useState([] as any);
  const [backdrop, setBackdrop] = useState([] as any);
  const [form] = useForm();
const { t } = useTranslation();

  const handleSubmit = async (values: Courses) => {
    setIsAdding(true);
    values = {
      _id: _id,
      ...data,
      images: {
        poster: poster.url == undefined ? (!Array.isArray(poster) ? poster : '') : poster?.url?.replace('https://cloudtv.akamaized.net', ''),
        background: backdrop?.url == undefined ? (!Array.isArray(backdrop) ? backdrop : '') : backdrop?.url?.replace('https://cloudtv.akamaized.net', ''),
      },
      query_params: {
        instance: clientName,
        cms: deployment?.name ?? '',
      },
    };
    
    let response = await cmsService.Update_Season(values as any);
    if (response.status_code === HttpStatusCodes.OK) {
      notification.success({
        message: t('updated-successfully'),
      });
    } else {
      notification.error({
        message: t('an-error-occured'),
      });
    }
  };

  useEffectOnce(() => {
    //setLogo(data.data.images.logo);
    setBackdrop(data.data.images.background);
    setPoster(data.data.images.poster);
    form.setFieldsValue(data.data);
  });

  return (
    <div className="container-fluid ">
      <Form
        form={form}
        onFinish={(values: any) => {
          handleSubmit(values);
        }}
        onFinishFailed={(errorInfo) => {
          
        }}
      >
        <div className="card mt-4 pb-4">
          <div className="card-body card-ch-padding">
            <ImageUploader
              onSubmit={(values: any) => {
                setPoster(values);
              }}
              initialData={{
                client: clientName,
                deployment: deployment,
                service: undefined,
                uploadedImage: poster,
                uploadName: t('season-poster'),
                uploadType: 'poster',
                path: STORAGE_ENDPOINTS(clientName, deployment?.name, service?.name).CONTENT.SERIES.IMAGES,
              }}
            />
            <ImageUploader
              onSubmit={(values: any) => {
                setBackdrop(values);
              }}
              initialData={{
                client: clientName,
                deployment: deployment,
                service: undefined,
                uploadedImage: backdrop,
                uploadName: t('season-background'),
                uploadType: 'background',
                path: STORAGE_ENDPOINTS(clientName, deployment?.name, service?.name).CONTENT.SERIES.IMAGES,
              }}
            />
            {/* <ImageUploader
              onSubmit={(values: any) => {
                setLogo(values);
              }}
              initialData={{
                notMandatory: true,
                uploadedImage: logo,
                uploadName: 'Season Logo',
                uploadType: 'logo',
                path: STORAGE_ENDPOINTS(clientName, deployment?.name, service?.name).CONTENT.SERIES.IMAGES,
              }}
            /> */}
          </div>
        </div>
        <div className="text-right">
          <Button htmlType="submit" disabled={isAdding} loading={isAdding} className="mt-4 ant-btn btn btn-secondary rounded-pill width-240 height-48">
            {t('update-season')}
          </Button>
        </div>
      </Form>
    </div>
  );
};

export default AddNewMusicAlbum;
